import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Form, Row } from 'react-bootstrap'
import InputBox from '../../../component/Input/InputBox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faSave } from '@fortawesome/free-solid-svg-icons'
import XcnButton from '../../../component/XcnButton/XcnButton'
import { CorpsService } from '../../../services/admin.service'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'



const PhysicalArch = () => {
    const params: any = useParams();

    const [editEnabled, setEditEnabled] = useState<boolean>(false);
    const [physicalArchitectData, setPhysicalArchitectData] =
        useState({
            ExmaCenterType: "",
            totalGateEntries: "",
            oneGateUsedForEntry: false,
            totalLabs: "",
            isMultiTenant: false,
            residentailsFlatsAvailable: false,
            entryToExamPremiseAvailable: false,
            accessiblityOfExamBuildingFromOtherBuilding: false,
            restroomInLab: false,
            surveillanceOfRestroomPath: false,
            falseDoorInRestroom: false,
            serverSeparatedFromExamLab: false,
            blindSpotInLab: false,
            StorageAreaForCandidateBags: false,
            sufficientGapInNodes: false,
            heightPartitionBetweenNdes: false,
            surveillanceOfVM: false,
            coachingInstitutionNearExamBuilding: false,
        });

    const handleChange = (e: any) => {
        let newData: any;
        if (e.target.type === "checkbox") {
            newData = {
                ...physicalArchitectData,
                [e.target.name]: e.target.checked,
            };
        } else {
            newData = {
                ...physicalArchitectData,
                [e.target.name]: e.target.value,
            };
        }
        setPhysicalArchitectData(newData);
    };

    function removeEmpty(obj: any) {
        return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== ""));
    }

    const getCenterDetails = async () => {
        await CorpsService.getCenterById(params.id)
            .then((res) => {
                setPhysicalArchitectData(res.data.center.physicalArchitecture)
            })
            .catch((error) => {
                toast.error(
                    `${error.response.data.message}`
                );
            });
    };

    useEffect(() => {
        getCenterDetails();
    }, []);

    useEffect(() => {
    }, [physicalArchitectData]);

    const updateCenterData = async () => {

        await CorpsService.updateCenterData(params.id, {
            physicalArchitecture: removeEmpty(physicalArchitectData),
        })
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Data updated successfully.");
                    setEditEnabled(false);
                    getCenterDetails();
                }
            })
            .catch((error) => {
                toast.error(
                    `${error.response.data.message}`
                );
            });
    };

    return (
        <>
            <Container fluid>
                <Card className="xcn-card mt-3">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h5 className="text-secondary fw-bold">Physical Architecture</h5>
                        <div>
                            {editEnabled ? (
                                <XcnButton
                                    text={
                                        <>
                                            <FontAwesomeIcon icon={faSave} className="me-2" />
                                            Save
                                        </>
                                    }
                                    variant="success"
                                    onClick={updateCenterData}
                                />
                            ) : (
                                <XcnButton
                                    text={
                                        <>
                                            <FontAwesomeIcon icon={faEdit} className="me-2" />
                                            Edit
                                        </>
                                    }
                                    variant="warning"
                                    onClick={() => setEditEnabled(true)}
                                />
                            )}
                        </div>
                    </div>

                    <div className="p-4">
                        <Row>
                            <Col md={6}>
                                <InputBox
                                    type="text"
                                    name="ExmaCenterType"
                                    label="Exam Center Type: College/School/Training Institute/Test Center"
                                    defaultValue={physicalArchitectData?.ExmaCenterType}
                                    onChange={handleChange}
                                    disabled={!editEnabled}
                                />
                            </Col>
                            <Col md={6}>
                                <InputBox
                                    type="number"
                                    name="totalGateEntries"
                                    label="Total number of entry/exit gates available at the center"
                                    defaultValue={String(physicalArchitectData?.totalGateEntries)}
                                    onChange={handleChange}
                                    disabled={!editEnabled}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md={6}>
                                <InputBox
                                    type="number"
                                    name="totalLabs"
                                    label="Total Number of exam Lab/Floor"
                                    defaultValue={String(physicalArchitectData?.totalLabs)}
                                    onChange={handleChange}
                                    disabled={!editEnabled}
                                />
                            </Col>
                        </Row>
                    </div>
                    <div className="d-flex justify-content-start align-items-center flex-wrap gap-4 p-4">
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="oneGateUsedForEntry"
                                label="Only one gate used for entry/exit"
                                checked={physicalArchitectData?.oneGateUsedForEntry}
                                name="oneGateUsedForEntry"
                                onChange={handleChange}
                                disabled={!editEnabled}
                            // onChange={props.onChange}
                            />
                        </span>
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="isMultiTenant"
                                label="Is the exam building multi-tenant"
                                // checked={props.updateCenterFacilities?.drinkingWater}
                                checked={physicalArchitectData?.isMultiTenant}
                                disabled={!editEnabled}
                                name="isMultiTenant"
                                onChange={handleChange}
                            // onChange={props.onChange}
                            />
                        </span>
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="residentailsFlatsAvailable"
                                label="Any residential flats available in exam building?"
                                // checked={props.updateCenterFacilities?.drinkingWater}
                                checked={physicalArchitectData?.residentailsFlatsAvailable}
                                disabled={!editEnabled}
                                name="residentailsFlatsAvailable"
                                onChange={handleChange}
                            // onChange={props.onChange}
                            />
                        </span>
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="entryToExamPremiseAvailable"
                                label="Dedicated entry to exam premise available"
                                // checked={props.updateCenterFacilities?.drinkingWater}
                                checked={physicalArchitectData?.entryToExamPremiseAvailable}
                                disabled={!editEnabled}
                                name="entryToExamPremiseAvailable"
                                onChange={handleChange}
                            // onChange={props.onChange}
                            />
                        </span>
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="accessiblityOfExamBuildingFromOtherBuilding"
                                label="Exam building accessibility with adjacent non exam or residential buildings"
                                checked={physicalArchitectData?.accessiblityOfExamBuildingFromOtherBuilding}
                                disabled={!editEnabled}
                                // checked={props.updateCenterFacilities?.drinkingWater}
                                name="accessiblityOfExamBuildingFromOtherBuilding"
                                onChange={handleChange}
                            // onChange={props.onChange}
                            />
                        </span>
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="restroomInLab"
                                label="Are washroom outside the lab"
                                // checked={props.updateCenterFacilities?.drinkingWater}
                                checked={physicalArchitectData?.restroomInLab}
                                disabled={!editEnabled}
                                name="restroomInLab"
                                onChange={handleChange}
                            // onChange={props.onChange}
                            />
                        </span>
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="surveillanceOfRestroomPath"
                                label="Washroom pathway under surveillance"
                                // checked={props.updateCenterFacilities?.drinkingWater}
                                checked={physicalArchitectData?.surveillanceOfRestroomPath}
                                disabled={!editEnabled}
                                name="surveillanceOfRestroomPath"
                                onChange={handleChange}
                            // onChange={props.onChange}
                            />
                        </span>
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="blindSpotInLab"
                                label="Blind spots in lab"
                                // checked={props.updateCenterFacilities?.drinkingWater}
                                checked={physicalArchitectData?.blindSpotInLab}
                                disabled={!editEnabled}
                                name="blindSpotInLab"
                                onChange={handleChange}
                            // onChange={props.onChange}
                            />
                        </span>
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="falseDoorInRestroom"
                                label="extra door in washroom leading out of the center, into another lab or empty room"
                                // checked={props.updateCenterFacilities?.drinkingWater}
                                checked={physicalArchitectData?.falseDoorInRestroom}
                                disabled={!editEnabled}
                                name="falseDoorInRestroom"
                                onChange={handleChange}
                            // onChange={props.onChange}
                            />
                        </span>
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="StorageAreaForCandidateBags"
                                label="Separate area for candidate belongings"
                                // checked={props.updateCenterFacilities?.drinkingWater}
                                checked={physicalArchitectData?.StorageAreaForCandidateBags}
                                disabled={!editEnabled}
                                name="StorageAreaForCandidateBags"
                                onChange={handleChange}
                            // onChange={props.onChange}
                            />
                        </span>
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="serverSeparatedFromExamLab"
                                label="Physical separation between exam labs, server room and area from other environment"
                                // checked={props.updateCenterFacilities?.drinkingWater}
                                checked={physicalArchitectData?.serverSeparatedFromExamLab}
                                name="serverSeparatedFromExamLab"
                                onChange={handleChange}
                                disabled={!editEnabled}
                            // onChange={props.onChange}
                            />
                        </span>
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="sufficientGapInNodes"
                                label="Sufficient gap between nodes"
                                // checked={props.updateCenterFacilities?.drinkingWater}
                                checked={physicalArchitectData?.sufficientGapInNodes}
                                disabled={!editEnabled}
                                name="sufficientGapInNodes"
                                onChange={handleChange}
                            // onChange={props.onChange}
                            />
                        </span>
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="heightPartitionBetweenNdes"
                                label="Sufficient height of physical partition between nodes"
                                // checked={props.updateCenterFacilities?.drinkingWater}
                                checked={physicalArchitectData?.heightPartitionBetweenNdes}
                                name="heightPartitionBetweenNdes"
                                onChange={handleChange}
                                disabled={!editEnabled}
                            // onChange={props.onChange}
                            />
                        </span>
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="surveillanceOfVM"
                                label="VM/Director room to be checked thoroughly and under surveillance"
                                // checked={props.updateCenterFacilities?.drinkingWater}
                                checked={physicalArchitectData?.surveillanceOfVM}
                                disabled={!editEnabled}
                                name="surveillanceOfVM"
                                onChange={handleChange}
                            // onChange={props.onChange}
                            />
                        </span>
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="coachingInstitutionNearExamBuilding"
                                label="Coaching institute available in or near the building"
                                checked={physicalArchitectData?.coachingInstitutionNearExamBuilding}
                                // checked={props.updateCenterFacilities?.drinkingWater}
                                name="coachingInstitutionNearExamBuilding"
                                onChange={handleChange}
                                disabled={!editEnabled}
                            // onChange={props.onChange}
                            />
                        </span>
                    </div>
                </Card>
            </Container>
        </>
    )
}

export default PhysicalArch