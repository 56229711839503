import { useState, useEffect } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { CorpsService } from "../../services/admin.service";
import { UploadService } from "../../services/upload.service";
import MyUploader from "../Dropzone/dropzone";
import InputBox from "../Input/InputBox";
import XcnModal from "./XcnModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";


interface IBuildingImages {
    showBuildingImage: any
    setShowBuildingImage: any,
    buildingId: string,
}

const BuildingImagesModal = ({showBuildingImage,
    setShowBuildingImage,
    buildingId}: IBuildingImages) => {

    const [buildingImageData, setBuildingImageData] = useState<any>();
    const [buildingImageLabel, setBuildingImageLabel] = useState<any>();
    const [buildingImageUploaded, setBuildingImageUploaded] = useState<any>(false);
    const [buildingImagesFromBackend, setBuildingImagesFromBackend] = useState<any>();
    const [selectedImageIds, setSelectedImageIds] = useState<string[]>([]);
    const [selectedImageIndices, setSelectedImageIndices] = useState<number[]>([]);


    const handleChange = (e: any) => {
        let newData: any;
        
        newData = {
        ...buildingImageLabel,
        [e.target.name]: e.target.value,
        };
        setBuildingImageLabel(newData);
      };

    const fileSelectedHandler = async (e: any, name: string) => {
        const fd = new FormData();
        for (let i of e) {
            fd.append("upload", i);
        }
        await UploadService.uploadFileToS3(fd).then((res: any) => {
            if (res.status === 200) {
                let imageData = res.data && res.data[0];
                setBuildingImageData({ [name]: imageData })
                setBuildingImageUploaded(true)
                toast.success(`File(s) uploaded`);
                getBuildingImages(buildingId)
            } else {
                toast.error("Something went wrong");
            }
        });

    };

    const handleImageSelection = (index: number) => {
        const updatedImages = [...buildingImagesFromBackend];
        updatedImages[index].selected = !updatedImages[index].selected;
        setBuildingImagesFromBackend(updatedImages);
      
        const selectedIds = updatedImages
          .filter((image: any) => image.selected)
          .map((image: any) => image._id);
        const selectedIndices = updatedImages
          .map((image: any, idx: number) => (image.selected ? idx : -1))
          .filter((idx: number) => idx !== -1);
        setSelectedImageIds(selectedIds);
        setSelectedImageIndices(selectedIndices);
    };

    const saveImage = async () => {
        if(buildingImageData?.buildingImage && buildingImageLabel?.buildingImageLabel){
            const payload = {
                images: [{url : buildingImageData?.buildingImage, label: buildingImageLabel?.buildingImageLabel}],
            }
            await CorpsService.saveBuildingImage(buildingId, payload).then((res) => {
                if(res.status === 200){
                    getBuildingImages(buildingId);
                    return true
                }
                else{
                    toast.error("Unable to save Building images.")
                }
            })
        }
        else{
            toast.error("Building Label and Building Image both are required.")
        }
    }

    const getBuildingImages = async(buildingId?: any) => {
        if(buildingId){
            await CorpsService.getBuildingImages(buildingId).then((res: any) => {
                if(res.status === 200){
                    setBuildingImagesFromBackend(res.data.images);
                }
                else{
                    toast.error("Unable to get Building Images.")
                }
            })
        }
    }

    const handleDeleteImages = async () => {
        if (selectedImageIds.length > 0) {
          const payload = {
            ids: selectedImageIds,
          };
            await CorpsService.deleteBuildingImages(buildingId, payload).then((res) => {
                if(res.status === 200){
                    const updatedImages = [...buildingImagesFromBackend];
                    selectedImageIndices.forEach((index) => {
                      updatedImages[index].selected = false;
                    });
                    setBuildingImagesFromBackend(updatedImages);
                    setSelectedImageIds([]);
                    setSelectedImageIndices([]);
                    getBuildingImages(buildingId)
                }
                else{
                    toast.error("Unable to delete Building Images.")
                }
            })      
        }
      };

    useEffect(() => {
        getBuildingImages(buildingId)
    }, [buildingId])

    

    return (
      <XcnModal
        show={showBuildingImage}
        handleClose={() => setShowBuildingImage(false)} 
        body = {
            <>
            <Container>
                {selectedImageIds && selectedImageIds.length > 0 ? 
                <div className='d-flex justify-content-end align-items-center mb-2'>
                    <Button variant="danger" onClick={handleDeleteImages}>
                        Delete
                    </Button>
                </div>
                :
                ""
                }
                <Row>
                    {buildingImagesFromBackend && buildingImagesFromBackend.length > 0 ? 
                        (
                            buildingImagesFromBackend.map((image: any, index: number) => {
                                // eslint-disable-next-line no-lone-blocks
                                {if(image != null){
                                    return (
                                        <Col md={4}>
                                            <div key={index} className='mb-3'>
                                                <img src={image?.url} width="100%" />
                                                <div className='text-center mt-2'>
                                                    <Button
                                                        variant={image.selected ? 'success' : 'secondary'}
                                                        onClick={() => handleImageSelection(index)}
                                                        size='sm'
                                                    >
                                                        {image.selected ? 'Selected' : 'Select'}
                                                    </Button>
                                                    <span className='ms-2'><b>{image?.label}</b></span>
                                                </div>
                                            </div>
                                        </Col>
                                    );
                                }}
                            })
                        )  : 
                        <Col md={4}>
                            <b>No Image found.</b>
                        </Col>
                    }
                </Row>
                <hr />
                <h6 className='mt-2'>Add Building Image:</h6>
                <Row className='mt-5'>
                    <Col md={12}>
                        <InputBox
                            type="text"
                            name="buildingImageLabel"
                            label="Building Image Label"
                            onChange={handleChange}
                            className='mb-2'
                        />
                        {buildingImageUploaded ? 
                            <div>
                            <FontAwesomeIcon icon={faCheckCircle} className="me-1 text-success" />
                                File Uploaded
                            </div>
                            :
                            ""
                        }
                        <MyUploader
                            fileSelectedHandler={(e: any) =>
                                fileSelectedHandler(e, "buildingImage")
                            }
                        />
                        <Button onClick={saveImage} className='mt-2'>
                            Save
                        </Button>
                    </Col>
                </Row>
            </Container>
          </>
        }     
        heading = "Building Images"
        size="lg"
        fullscreen='lg-down'
      />
  )
}

export default BuildingImagesModal