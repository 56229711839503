import React from 'react'
import { Outlet } from 'react-router-dom'
import TopBar from '../component/TopBar/TopBar'
import dashboardRoutes from '../router/dashboard.routes'

const MainIndex = () => {
    return (
        <>
            {/* <SideBar
                routes={dashboardRoutes}
                panelName=""
                navUrlPos={2}
                baseUrl="/"
            /> */}
            <TopBar
                menuData={dashboardRoutes}
                panelName=""
            />
            <Outlet />
        </>
    )
}

export default MainIndex