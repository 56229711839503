import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Card, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import InputBox from '../Input/InputBox';
import XcnModal from './XcnModal';

interface IViewBuildingPlan {
    showViewBuildingPlan: any
    setShowViewBuildingPlan: any,
    buildingId: string,
    floorData: any
}

export default function ViewFloorModal({
    showViewBuildingPlan,
    setShowViewBuildingPlan,
    buildingId,
    floorData
}: IViewBuildingPlan) {


    return (
        <>
            <XcnModal
                size="lg"
                show={showViewBuildingPlan}
                handleClose={() => setShowViewBuildingPlan(false)}
                body={
                    <>
                        {floorData && floorData.length > 0 ? floorData.map((data: any, index: number) => {
                            return (
                                <Card className='xcn-card border border-1 border-muted mt-3 rounded'>
                                    <Card.Body>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <span>
                                                <span className="fw-bold">Floor Name: </span>
                                                <span>{data.name}</span>
                                            </span>
                                            <span>
                                                <span className="fw-bold">Floor Number: </span>
                                                <span>{data.number}</span>
                                            </span>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center mt-3">
                                            <h6 className='fw-bold text-primary'>
                                                Labs ({data.labs && data.labs.length})
                                            </h6>
                                            <h6 className='fw-bold text-primary'>
                                                System Count({data.labs && data.labs.length > 0 ? data.labs.reduce((a: any, b: any) => a + b.computerCount, 0) : 0})

                                            </h6>
                                        </div>
                                        <div className='bg-light mt-2 rounded p-2'>

                                            {data.labs && data.labs.length > 0 && data.labs.map((lab: any, index: number) => {
                                                return (
                                                    <div className='my-3'>
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: 'flex-end' }}>
                                                            <Button size="sm" variant='secondary' className="mb-2">
                                                                <a href={lab && lab.labImage} className="text-decoration-none text-white" download={true}>
                                                                    Download Lab Image
                                                                </a>
                                                            </Button>
                                                        </div>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <span>
                                                                <span className="fw-bold">Name: </span>
                                                                <span>{lab.name}</span>
                                                            </span>
                                                            <span>
                                                                <span className="fw-bold">Computer Count: </span>
                                                                <span>{lab.computerCount}</span>
                                                            </span>

                                                        </div>
                                                        <div className='d-flex justify-content-between align-items-center mt-1'>
                                                            <span>
                                                                <span className="fw-bold">DVR Count: </span>
                                                                <span>{lab.dvrCount}</span>
                                                            </span>
                                                            <span>
                                                                <span className="fw-bold">Camera Count: </span>
                                                                <span>{lab.cameraCount}</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }

                                        </div>

                                    </Card.Body>
                                </Card>
                            )
                        }) : "No Floors Available"}
                    </>
                }
                heading="View Floors"
            />
        </>
    )
}