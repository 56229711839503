import { faDeleteLeft, faEllipsisVertical, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useEffect, useState } from "react";
import { Card, Table, Dropdown } from "react-bootstrap";
import { useNavigate, useParams } from 'react-router-dom';
import ViewLabComputers from "../../../component/Modals/ViewLabComputers";
import CustomToggle from "../../../component/Menu/CustomMenu";
import { CorpsService } from "../../../services/admin.service";
import { toast } from "react-toastify";

interface IAllLabs {
  labsData: any
  setLabsData: any
}


export default function AllLabs({ labsData, setLabsData }: IAllLabs) {
  const [selectedLab, setSelectedLab] = useState<any>([]);
  const [selectedLabId, setSelectedLabId] = useState<any>();
  const [show, setShow] = useState<boolean>(false);
  
  const removeLab = async (labId: any) => {
    await CorpsService.deleteLab(labId).then(res => {
      if (res.status === 200) {
        toast.success("Removed successfully");
        const updatedLabsData = labsData.filter((lab: any) => String(lab._id) !== String(labId));
        setLabsData(updatedLabsData);
      }
    })
  }
  return (
    <>
      <ViewLabComputers
        show={show}
        handleClose={() => {
          setShow(false);
        }}
        data={selectedLab}
        selectedLabId={selectedLabId}
      />
      <Card className="xcn-card mt-4">
        <Table borderless striped>
          <thead>
            <tr>
              <th>#</th>
              <th>Lab Name</th>
              <th>Building Name</th>
              <th>Number Of Nodes</th>
              <th>Center Id</th>
              <th>Last Estimation Date</th>
            </tr>
          </thead>
          <tbody>
            {labsData?.map((data: any, index: number) => (
              <tr className="xcn-table-tr" key={data._id}>
                <td>{index + 1}</td>
                <td>{data.labName}</td>
                <td>{data.buildingName || "---"}</td>
                <td>{data.computerIds.length}</td>
                <td>{data.centerId}</td>
                <td>{moment(data.updatedAt).format("DD-MM-YYYY")}</td>

                <td>
                    <Dropdown>
                      <Dropdown.Toggle
                          as={CustomToggle}
                          id="dropdown-custom-components"
                      >
                          <FontAwesomeIcon icon={faEllipsisVertical} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                          <Dropdown.Item onClick={() => {
                            setSelectedLab(data);
                            setShow(true);
                            setSelectedLabId(data._id);
                          }}>
                              <FontAwesomeIcon
                                icon={faEye}
                                className="text-info"
                              />
                              <span className="fw-bold text-secondary fs-12 ms-2">View</span>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => {
                              removeLab(data?._id);
                              setSelectedLabId(data?._id);
                          }}>
                              <FontAwesomeIcon
                                  icon={faTrash}
                                  className="text-danger xcn-link-pointer"
                              />
                              <span
                                  className="fw-bold text-secondary fs-12 ms-2">
                                  Delete
                              </span>
                          </Dropdown.Item>
                      </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>
    </>
  );
}