import { faEdit, faEllipsisVertical, faEye, faMessage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { Badge, Button, Dropdown, Image, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import BooleanStatus from '../../../helpers/BooleanStatus';

interface ICenterEmployees {
    employees: any[]
}

export default function CenterEmployees(props: ICenterEmployees) {
    const renderTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {props}
        </Tooltip>
    );
    return (
        <>
            <Table borderless striped responsive>
                <thead>
                    <tr>
                        <th>Sr. No</th>
                        <th>Employee Name</th>
                        <th>Image</th>
                        <th>Email</th>
                        <th>Father Name</th>
                        <th>Gender</th>
                        <th>Phone</th>
                        <th>Type Of Employee</th>
                        <th>Status</th>
                        <th>Address</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.employees && props.employees.length > 0 ?
                            props.employees.map((data: any, index: number) => {
                                return (
                                  <tr className="xcn-table-tr" key={data._id}>
                                    <td>{index + 1}</td>
                                    <td className="fw-bold xcn-text-12">
                                      {data && data.employeeName}
                                    </td>
                                    <td>
                                      {data && data.image && <Image src={data.image} roundedCircle width={30} height={30} className="ms-2" />}
                                    </td>
                                    <td>
                                        {data && data.email}
                                    </td>
                                    
                                    <td xcn-text-12>
                                      {data && data.fatherName}
                                    </td>
                                    <td className=" xcn-text-12">
                                      {data && data.gender}
                                    </td>
                                    <td>{data && data.phoneNumber}</td>
                                    <td>{data && data.typeOfEmployee || "---"}</td>
                                    
                                    <td>
                                      {data.blocked && data.blocked == true ? <Badge bg="danger">Blocked</Badge> : 
                                        <Badge bg="success">Allowed</Badge> }                                    
                                        </td>
                                    <td>
                                      {data && data.address}
                                    </td>
                                  </tr>
                                );
                            }) :
                            <tr>
                                <td colSpan={10} className="text-danger fw-bold">
                                    No Employees Registered
                                </td>
                            </tr>
                    }

                </tbody>
            </Table>
        </>
    )
}