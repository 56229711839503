import makeRequest from "./makeRequest";
import urls from "./urls";

export default class AuthService {

    static async Login(payload: any) {
        return await makeRequest(urls.public.login, "POST", payload)
    }

}

