import { faBuilding, faChalkboard, faChalkboardTeacher, faHome, faTimesCircle, faLocationPin, faChartLine, faNewspaper, faBookJournalWhills, faBars } from '@fortawesome/free-solid-svg-icons'
import IRouter from '../interfaces/IRouter';
import CenterIndex from '../View/Center/Center.index';
import Dashboard from '../View/Dashboard/Dashboard';


// here dashboardRoutes is follow Poperty of IRouter
const dashboardRoutes: IRouter[] = [
  {
    path: "corps",
    element: <Dashboard />,
    navbarShow: false,
    activeIcon: faNewspaper,
    name: "Details",
  },
  {
    path: "center/:id",
    element: <CenterIndex />,
    navbarShow: false,
    activeIcon: faNewspaper,
    name: "Details",
  },
  {
    path: "*",
    element: <>Not Found</>,
    navbarShow: false,
    activeIcon: faTimesCircle,
    name: "Notfound",
  },
];

export default dashboardRoutes;