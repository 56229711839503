import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Form, Row } from 'react-bootstrap'
import InputBox from '../../../component/Input/InputBox'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { faSave, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import XcnButton from '../../../component/XcnButton/XcnButton'
import { CorpsService } from '../../../services/admin.service'


const Manpower = () => {
    const params: any = useParams();

    const [editEnabled, setEditEnabled] = useState<boolean>(false);
    const [manpowerInfo, setManpowerInfo] = useState({
        parternsOwningCenter: "",
        roleCountOfManpower: "",
        backgroundCheckOfManpower: false,
        idOfManpowerAtCenter: false,
        invigilatorsComingFromOutsideCity: false,
        puproseOfLISPOwner: "",
        otherSourcesOfIncomeforPartner: "",
        invigilatorsPermanent: false,
        manpowerPrepapringForExam: false,
    });

    const handleChange = (e: any) => {
        let newData: any;
        if (e.target.type === "checkbox") {
            newData = {
                ...manpowerInfo,
                [e.target.name]: e.target.checked,
            };
        } else {
            newData = {
                ...manpowerInfo,
                [e.target.name]: e.target.value,
            };
        }
        setManpowerInfo(newData);
    };

    function removeEmpty(obj: any) {
        return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== ""));
    }

    const getCenterDetails = async () => {
        await CorpsService.getCenterById(params.id)
            .then((res) => {
                setManpowerInfo(res.data.center.manPower);
            })
            .catch((error) => {
                toast.error(
                    `${error.response.data.message}`
                );
            });
    };

    useEffect(() => {
        getCenterDetails();
    }, []);

    useEffect(() => {

    }, [manpowerInfo]);

    const updateCenterData = async () => {
        await CorpsService.updateCenterData(params.id, {
            manPower: removeEmpty(manpowerInfo),
        })
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Data updated successfully.");
                    setEditEnabled(false);
                    getCenterDetails();
                }
            })
            .catch((error) => {
                toast.error(
                    `${error.response.data.message}`
                );
            });
    };
    return (
        <>
            <Container fluid>
                <Card className="xcn-card mt-3">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h5 className="text-secondary fw-bold">Manpower</h5>
                        <div>
                            {editEnabled ? (
                                <XcnButton
                                    text={
                                        <>
                                            <FontAwesomeIcon icon={faSave} className="me-2" />
                                            Save
                                        </>
                                    }
                                    variant="success"
                                    onClick={updateCenterData}
                                />
                            ) : (
                                <XcnButton
                                    text={
                                        <>
                                            <FontAwesomeIcon icon={faEdit} className="me-2" />
                                            Edit
                                        </>
                                    }
                                    variant="warning"
                                    onClick={() => setEditEnabled(true)}
                                />
                            )}
                        </div>
                    </div>
                    <div className="p-4">
                        <Row>
                            <Col md={6}>
                                <InputBox
                                    type="number"
                                    name="parternsOwningCenter"
                                    label="No. of partners owning the center"
                                    defaultValue={String(manpowerInfo?.parternsOwningCenter)}
                                    disabled={!editEnabled}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col md={6}>
                                <InputBox
                                    type="number"
                                    name="roleCountOfManpower"
                                    label="Role wise count of manpower at the center"
                                    defaultValue={String(manpowerInfo?.roleCountOfManpower)}
                                    disabled={!editEnabled}
                                    onChange={handleChange}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md={6}>
                                <InputBox
                                    type="text"
                                    name="puproseOfLISPOwner"
                                    label="Purpose of LISP owner to operate the center"
                                    defaultValue={manpowerInfo?.puproseOfLISPOwner}
                                    disabled={!editEnabled}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col md={6}>
                                <InputBox
                                    type="text"
                                    name="otherSourcesOfIncomeforPartner"
                                    label="Any other sources of income for Partner/Owner"
                                    defaultValue={manpowerInfo?.otherSourcesOfIncomeforPartner}
                                    disabled={!editEnabled}
                                    onChange={handleChange}
                                />
                            </Col>
                        </Row>
                    </div>

                    <div className="d-flex justify-content-start align-items-center flex-wrap gap-4 p-4">
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="backgroundCheckOfManpower"
                                label="Background Check done for center manpowers by Partner"
                                checked={manpowerInfo?.backgroundCheckOfManpower}
                                name="backgroundCheckOfManpower"
                                disabled={!editEnabled}
                                onChange={handleChange}
                            // onChange={props.onChange}
                            />
                        </span>
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="idOfManpowerAtCenter"
                                label="Checked the identity proof of all manpower available"
                                // checked={props.updateCenterFacilities?.drinkingWater}
                                checked={manpowerInfo?.idOfManpowerAtCenter}
                                disabled={!editEnabled}
                                onChange={handleChange}
                                name="idOfManpowerAtCenter"
                            />
                        </span>
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="invigilatorsComingFromOutsideCity"
                                label="Any invigilators coming from outside the city vicinity"
                                // checked={props.updateCenterFacilities?.drinkingWater}
                                checked={manpowerInfo?.invigilatorsComingFromOutsideCity}
                                disabled={!editEnabled}
                                onChange={handleChange}
                                name="invigilatorsComingFromOutsideCity"

                            // onChange={props.onChange}
                            />
                        </span>
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="manpowerPrepapringForExam"
                                label="Any manpower preparing for any competitive exams"
                                // checked={props.updateCenterFacilities?.drinkingWater}
                                checked={manpowerInfo?.manpowerPrepapringForExam}
                                disabled={!editEnabled}
                                onChange={handleChange}
                                name="manpowerPrepapringForExam"
                            />
                        </span>
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="invigilatorsPermanent"
                                label="Are the invigilators permanent"
                                // checked={props.updateCenterFacilities?.drinkingWater}
                                disabled={!editEnabled}
                                onChange={handleChange}
                                checked={manpowerInfo?.invigilatorsPermanent}
                                name="invigilatorsPermanent"
                            />
                        </span>
                    </div>
                </Card>
            </Container>
        </>
    )
}

export default Manpower