import React from "react";
import { Button, Modal } from "react-bootstrap";

export interface IXcnModal{
    show: any,
    handleClose: any
    body: any
    heading: any
    size?:any
    backdrop?:any
    centered?:boolean
    fullscreen?:any
    headerClassName?: string
}

export default function XcnModal(props: IXcnModal) {
    return (
        <Modal show={props.show} onHide={props.handleClose} size={props.size} backdrop={props.backdrop} centered={props.centered} fullscreen={props.fullscreen}>
            <Modal.Header closeButton className={props.headerClassName}>
                <Modal.Title>{props.heading}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.body}</Modal.Body>
        </Modal>
    )
}