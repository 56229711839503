import React, { useEffect, useState } from 'react';
import { faEllipsisVertical, faEye, faInfoCircle, faPlus, faPlusCircle, faTimesCircle, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, Dropdown } from 'react-bootstrap';

import { toast } from 'react-toastify';
import ViewFloorModal from '../../../component/Modals/ViewFloor.modal';
import InfoModal from '../../../component/Modals/BuildingInfo.modal';
import CustomToggle from '../../../component/Menu/CustomMenu';
import BuildingImagesModal from '../../../component/Modals/BuildingImages.Model';

interface ICenterBuildingsTable {
    buildings: any[]
}

export default function CenterBuildingsTable(props: ICenterBuildingsTable) {
    const [showAddFloor, setShowAddFloor] = useState<boolean>(false);
    const [showViewFloor, setShowViewFloor] = useState<boolean>(false);
    const [showBuildingInfo, setShowBuildingInfo] = useState<boolean>(false)

    const [selectedBuilding, setSelectedBuilding] = useState<string>("");
    const [selectedFloorData, setSelectedFloorData] = useState<any>({});
    const [showBuildingImage, setShowBuildingImage] = useState<boolean>(false);

    
    return (
        <>

            <ViewFloorModal
                showViewBuildingPlan={showViewFloor}
                setShowViewBuildingPlan={setShowViewFloor}
                buildingId={selectedBuilding}
                floorData={selectedFloorData}
            />

            <BuildingImagesModal
                showBuildingImage={showBuildingImage}
                setShowBuildingImage={setShowBuildingImage}
                buildingId={selectedBuilding}
            />

            <Table borderless striped>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Location</th>
                        <th>Floors</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.buildings && props.buildings.length > 0 ?
                            props.buildings.map((data: any, index: number) => {
                                console.log(data);
                                return (
                                    <tr className="xcn-table-tr" key={data._id}>
                                        <td>
                                            {index + 1}
                                        </td>
                                        <td className="fw-bold xcn-text-12">
                                            {data.name}
                                        </td>
                                        <td className="font-monospace xcn-text-12">
                                            {data.location}
                                        </td>
                                        <td className="fw-bold text-secondary">
                                            {data.floors.length}
                                            <FontAwesomeIcon icon={faPlusCircle} className="text-primary ms-2 xcn-link-pointer" onClick={() => {
                                                setSelectedBuilding(data._id)
                                                setShowAddFloor(true)
                                            }} />
                                            <FontAwesomeIcon icon={faEye} className="ms-1 text-info xcn-link-pointer" onClick={() => {
                                                setSelectedBuilding(data._id)
                                                setSelectedFloorData(data.floors)
                                                setShowViewFloor(true)
                                            }} />

                                        </td>
                                        <td >
                                        <Dropdown>
                                                <Dropdown.Toggle
                                                    as={CustomToggle}
                                                    id="dropdown-custom-components"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faEllipsisVertical}
                                                    />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => setShowBuildingInfo(true)}>
                                                        <FontAwesomeIcon icon={faInfoCircle} className="text-warning xcn-link-pointer" onClick={() => setShowBuildingInfo(true)} />
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={() => {
                                                        setSelectedBuilding(data?._id)
                                                        setShowBuildingImage(true)
                                                    }}>
                                                        <FontAwesomeIcon
                                                            icon={faUpload}
                                                            className="text-secondary xcn-link-pointer"
                                                        />
                                                        <span className="fw-bold text-secondary fs-12 ms-2">
                                                            Building Images
                                                        </span>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            
                                            <InfoModal buildingInfo={showBuildingInfo} setBuildingInfo={setShowBuildingInfo} sendInfo={data.otherInfo} />
                                        </td>
                                    </tr>
                                )
                            }) :
                            <tr>
                                <td colSpan={5} className="text-danger fw-bold">
                                    No Buildings Registered
                                </td>
                            </tr>
                    }

                </tbody>
            </Table>
        </>
    )
}