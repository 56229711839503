import React, { useEffect, useState } from 'react'
import { Button, Card, Container, Tab, Tabs } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom'
import { CorpsService } from '../../services/admin.service'
import ApprovalInfo from './Approval/ApprovalInfo';
import Building from './Building/Building';
import CenterDocument from './CenterDocument/CenterDocument';
import CenterDetails from './Details/CenterDetails';
import Manpower from './Manpower/Manpower';
import Netwrok from './Network/Netwrok';
import PhysicalArch from './PhysicalArch/PhysicalArch';
import Power from './Power/Power';
import Surveillance from './Surveillance/Surveillance';
import Employees from './Employees/Employees';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBackward } from '@fortawesome/free-solid-svg-icons';
import ViewLabDetails from './Labs/ViewLabDetails';
import SystemConfig from './Details/SystemConfig/SystemConfig';
import Safety from './Details/Safety/Safety';
import OtherInfo from './Details/OtherInfo/OtherInfo';

const CenterIndex = () => {

    const { id } = useParams();
    const navigate = useNavigate()

    const [key, setKey] = useState('details');

    const [centerData, setCenterData] = useState<any>();
    const [employeeDetails, setEmployeeDetails] = useState<any>();


    const getCenterDetails = async () => {
        await CorpsService.getCenterById(id).then((res) => {
            if (res.status === 200) {
                setCenterData(res.data)
            }
        }).catch(e => {
            console.log(e);
        })
    }

    const getCenterEmployees = async () => {
        await CorpsService.getCenterEmployees(id).then((res) => {
            if (res.status === 200) {
                setEmployeeDetails(res.data)
            }
        }).catch(e => {
            console.log(e);
        })
    }



    useEffect(() => {
        getCenterDetails();
        getCenterEmployees();
    }, [])

    return (
        <Container className=" p-5" fluid>
            <Button variant='secondary' className="mb-2" onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k: any) => setKey(k)}
                className="mb-3 fw-bold shadow-sm"
                style={{
                    background: "#ffffff",
                    color: "#000000",
                    padding: "10px",
                    outline: "none",
                    border: "none",
                    borderRadius: "10px"
                }}
            >
                <Tab eventKey="details" title="Details">
                    <CenterDetails />
                </Tab>
                <Tab eventKey="approval" title="Approvals">
                    <ApprovalInfo data={centerData?.center?.approvals} reload={getCenterDetails} />
                </Tab>
                <Tab eventKey="building" title="Buildings">
                    <Building />
                </Tab>
                <Tab eventKey="physicalArchitecture" title="Architecture">
                    <PhysicalArch />
                </Tab>
                <Tab eventKey="manpower" title="Manpower">
                    <Manpower />
                </Tab>
                <Tab eventKey="netwrokDetails" title="Network Details">
                    <Netwrok />
                </Tab>
                <Tab eventKey="centerDocument" title="Documents">
                    <CenterDocument />
                </Tab>
                <Tab eventKey="powerGenSet" title="Power Genset">
                    <Power />
                </Tab>
                <Tab eventKey="surveillance" title="Surveillance">
                    <Surveillance />
                </Tab>
                <Tab eventKey="systemConfig" title="System Config.">
                    <SystemConfig />
                </Tab>
                <Tab eventKey="safety" title="Safety">
                    <Safety />
                </Tab>
                <Tab eventKey="otherInfo" title="Other Info">
                    <OtherInfo />
                </Tab>
                <Tab eventKey="employees" title="Employees">
                    <Employees employeeDetails={employeeDetails} />
                </Tab>

                <Tab eventKey="labdetails" title="Labs">
                    <ViewLabDetails />
                </Tab>

            </Tabs>
        </Container>
    )
}

export default CenterIndex