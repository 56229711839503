import { faCircleRadiation, faDownload, faEllipsisVertical, faEye, faShare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Badge, Dropdown, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CorpsService } from "../../services/admin.service";
import CustomToggle from "../Menu/CustomMenu";
import CenterStatusModal from "../Modals/CenterStatus.modal";
import ResetPasswordModal from "../Modals/ResetPassword.modal";
import { saveAs } from 'file-saver';


interface IViewCentersTable {
  centers: any[];
  reload?: any

}

export default function ViewCentersTable(props: IViewCentersTable) {

  const navigate = useNavigate();

  const [modalData, setModalData] = useState<any>(undefined);
  const [showPasswordModal, setShowPasswordModal] = useState<any>(undefined);


  const handleDelete = async (centerId: string) => {
    await CorpsService.deleteCenters(centerId).then(res => {
      if (res.status === 200) {
        toast.success("Removed successfully");
        props.reload();
      }
    })
  };

  const resetPassword = async (centerId: any) => {
    await CorpsService.generatePassword(centerId).then(res => {
      if (res.status === 200) {
        setShowPasswordModal(res.data.password);
      }
    })
  }

  const downloadCenterDetails = async (centerId: any) => {
    await CorpsService.downloadCenterDetails(centerId).then(res => {
      if (res.status === 200) {
        const base64Data = res.data.split(',')[1];
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        saveAs(blob, 'center-details.pdf');
      }
    })
  }

  return (
    <div className="mt-3">
      <Table borderless striped responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Center Name</th>
            <th>Center Id</th>
            <th>Email</th>
            <th>State</th>
            <th>City</th>
            <th>Active</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {props.centers && props.centers.length > 0 ? (
            props.centers.map((data: any, index: number) => {
              return (
                <tr className={"xcn-table-tr"}>
                  <td>{index + 1}</td>
                  <td className="fw-bold xcn-text-12">{data?.name}</td>
                  <td className="fw-bold text-danger">{data?.centerId}</td>
                  <td className="font-monospace xcn-text-12">
                    {data?.centerEmail}
                  </td>
                  <td className="font-monospace xcn-text-12">
                    {data?.districtId?.state}
                  </td>
                  <td className="font-monospace xcn-text-12">
                    {data?.districtId?.district}
                  </td>
                  <td>
                    <Badge bg={data.isActive ? "primary" : "secondary"}>{data.isActive ? "Active" : "In Active"}</Badge>
                  </td>
                  <td className="text-center">
                    <Dropdown>
                      <Dropdown.Toggle
                        as={CustomToggle}
                        id="dropdown-custom-components"
                      >
                        <FontAwesomeIcon icon={faEllipsisVertical} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => navigate("/center/" + data._id)}
                        >
                          <FontAwesomeIcon
                            icon={faEye}
                            className="text-info xcn-link-pointer"
                          />
                          <span
                            className="fw-bold text-secondary fs-12 ms-2">
                            View
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setModalData(data);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCircleRadiation}
                            className="text-info xcn-link-pointer"
                          />
                          <span
                            className="fw-bold text-secondary fs-12 ms-2"
                          >
                            Active/Inactive
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => resetPassword(data?._id)}>
                          <FontAwesomeIcon
                            icon={faShare}
                            className="text-info xcn-link-pointer"
                          />
                          <span className="fw-bold text-secondary fs-12 ms-2">
                            Reset Password
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => downloadCenterDetails(data?._id)}>
                            <FontAwesomeIcon
                                icon={faDownload}
                                className="text-info xcn-link-pointer"
                            />
                            <span className="fw-bold text-secondary fs-12 ms-2">
                                Center Details
                            </span>
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            handleDelete(data?._id);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            className="text-danger xcn-link-pointer"
                          />
                          <span className="fw-bold text-secondary fs-12 ms-2">
                            Delete
                          </span>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={5} className="w-100 text-danger fw-bold">
                No Centers Registered
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <CenterStatusModal show={modalData} handleClose={() => setModalData(false)} reload={props.reload} />

      <ResetPasswordModal
        show={showPasswordModal}
        setShow={setShowPasswordModal}
      />
    </div>
  );
}