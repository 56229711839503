import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Card, Table, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import AllLabs from "./AllLabs";
import { CorpsService } from "../../../services/admin.service";

export default function ViewLabDetails() {
  const { id }: any = useParams();
  const [labsData, setLabsData] = useState<any>([]);


  const getCenterlabs = async () => {
    await CorpsService.getCenterlabs(id).then((res: any) => {
      setLabsData(res.data.labData);
    })
  }

  useEffect(() => {
    getCenterlabs();
  }, []);

  return (
    <>
      <Container fluid>
        <Card className="xcn-card mt-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span className="text-primary fw-bold fs-5">Registered Labs</span>
          </div>

          <AllLabs labsData={labsData} setLabsData={setLabsData} />
        </Card>
      </Container>
    </>
  );
}
