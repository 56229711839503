import { faGear, faArrowRightArrowLeft, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Container, Dropdown, DropdownButton, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import IRouter from '../../interfaces/IRouter';
import './Topbar.css';

interface ITopBar {
  menuData: IRouter[],
  panelName: string,
  baseUrl?: string
}

export default function TopBar({ menuData, panelName, baseUrl }: ITopBar) {

  let navigate = useNavigate();
  const location = useLocation();

  const showAllowedMenu = menuData.filter((routes) => routes.navbarShow === true)

  const handleLogout = () => {
    sessionStorage.removeItem("authToken");
    window.location.reload();
  };

  return (
    <>
      <Navbar className="pl-3 d-flex shadow-sm" bg="light">
      <Container fluid>
        <Navbar.Brand className='fw-bold'>NIXCEN CORP</Navbar.Brand>
        <div className="d-flex justify-content-between align-items-center">
          {
            showAllowedMenu.map((data, index) => {
              return (
                <>
                  <div key={index}
                    className={"xrg-nav-link" + (data.path == location.pathname.split("/")[2] ? " xrg-nav-selected" : " ")}
                    onClick={() => navigate(data.path)}
                  >
                    <div className='xrg-nav-item mt-3'>
                      <div className="xrg-nav-close d-flex align-items-center mb-3" style={{ marginLeft: "3rem" }}>
                        <span
                          className="nav-btn me-5"
                          style={{ cursor: "pointer", }}
                        >
                          {data.name}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )
            })
          }
            <Button className="m-2 fw-bold" size="sm" onClick={handleLogout}>Logout</Button>
        </div>
      </Container>
      </Navbar>
    </>
  );
}
