import { useEffect, useState } from "react";
import { CorpsService } from "../../../../services/admin.service";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import XcnButton from "../../../../component/XcnButton/XcnButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import InputBox from "../../../../component/Input/InputBox";


interface ISystemInformation {
    totalNoOfDesktopAvailable: Number,
    totalPCOfferedForExam: Number,
    totalLaptopAvailable: Number,
    totalLaptopOfferedForExam: Number,
    desktopLatopMakeModel: String,
    processor: String,
    ram: String,
    hddCapacity: String,
    operatingSystemName: String,
    isOSGenuine: boolean,
    isAntivirusInstalledOnAllSystem: boolean,
    isAntivirusLicensedVersion: boolean,
    antivirusName: String,
    browserNameAndVersion: String,
    keyboardAvailable: boolean,
    mouseAvailable: boolean,
    monitorType: String,
    displaySize: String
}

export default function SystemConfig() {

    const { id } = useParams()

    const [editEnabled, setEditEnabled] = useState<boolean>(false);
    const [systemInfoData, setSystemInfoData] = useState<ISystemInformation>();


    const handleChange = (e: any) => {
        let newData: any;
        if (e.target.type === "checkbox") {
            newData = {
                ...systemInfoData,
                [e.target.name]: e.target.checked,
            };
        } else {
            newData = {
                ...systemInfoData,
                [e.target.name]: e.target.value,
            };
        }
        setSystemInfoData(newData);
    };


    const getCenterDetails = async () => {
        await CorpsService.getCenterById(id)
            .then((res) => {
                setSystemInfoData(res.data.center.systemInformation
                );
            })
            .catch((error) => {
                toast.error(
                    error.response.status +
                    " Something went wrong " +
                    `${error.response.data.message}`
                );
            });
    };

    function removeEmpty(obj: any) {
        return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== ""));
    }

    const updateCenterData = async () => {
        await CorpsService.updateCenterData(id, {
            systemInformation: removeEmpty(systemInfoData)
        })
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Data updated successfully.");
                    getCenterDetails();
                    setEditEnabled(false);
                }
            })
            .catch((error) => {
                toast.error(
                    error.response.status +
                    " Something went wrong " +
                    `${error.response.data.message}`
                );
            });
    };

    useEffect(() => {
        getCenterDetails();
    }, []);

    return (
        <Container fluid>
            <Card className="xcn-card mt-3">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="text-secondary fw-bold">System Configuration</h5>
                    <div>
                        {editEnabled ? (
                            <XcnButton
                                text={
                                    <>
                                        <FontAwesomeIcon icon={faSave} className="me-2" />
                                        Save
                                    </>
                                }
                                variant="success"
                                onClick={updateCenterData}
                            />
                        ) : (
                            <XcnButton
                                text={
                                    <>
                                        <FontAwesomeIcon icon={faEdit} className="me-2" />
                                        Edit
                                    </>
                                }
                                variant="warning"
                                onClick={() => setEditEnabled(true)}
                            />
                        )}
                    </div>
                </div>
                <Row>
                    <Col md={6}>
                        <InputBox
                            type="number"
                            name="totalNoOfDesktopAvailable"
                            label="Total No. of Desktop Available"
                            defaultValue={systemInfoData?.totalNoOfDesktopAvailable}
                            disabled={!editEnabled}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col md={6}>
                        <InputBox
                            type="number"
                            name="totalPCOfferedForExam"
                            label="Total No. of PC offered for Exam"
                            defaultValue={systemInfoData?.totalPCOfferedForExam}
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={6}>
                        <InputBox
                            type="number"
                            name="totalLaptopAvailable"
                            label="Total No. of Laptop Available"
                            defaultValue={systemInfoData?.totalLaptopAvailable}
                            disabled={!editEnabled}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col md={6}>
                        <InputBox
                            type="number"
                            name="totalLaptopOfferedForExam"
                            label="Total No. of Laptop offered for Exam"
                            defaultValue={systemInfoData?.totalLaptopOfferedForExam}
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={6}>
                        <InputBox
                            type="text"
                            name="desktopLatopMakeModel"
                            label="Desktop Laptop Model"
                            defaultValue={systemInfoData?.desktopLatopMakeModel}
                            disabled={!editEnabled}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col md={6}>
                        <InputBox
                            type="text"
                            name="processor"
                            label="Processor"
                            defaultValue={systemInfoData?.processor}
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={6}>
                        <InputBox
                            type="text"
                            name="ram"
                            label="RAM"
                            defaultValue={systemInfoData?.ram}
                            disabled={!editEnabled}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col md={6}>
                        <InputBox
                            type="text"
                            name="hddCapacity"
                            label="HDD Capacity"
                            defaultValue={systemInfoData?.hddCapacity}
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={6}>
                        <InputBox
                            type="text"
                            name="operatingSystemName"
                            label="Operating System Name"
                            defaultValue={systemInfoData?.operatingSystemName}
                            disabled={!editEnabled}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col md={6}>
                        <InputBox
                            type="text"
                            name="antivirusName"
                            label="Antivirus Name"
                            defaultValue={systemInfoData?.antivirusName}
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={6}>
                        <InputBox
                            type="text"
                            name="browserNameAndVersion"
                            label="Browser Name and Version"
                            defaultValue={systemInfoData?.browserNameAndVersion}
                            disabled={!editEnabled}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col md={6}>
                        <InputBox
                            type="text"
                            name="monitorType"
                            label="Moniter Type"
                            defaultValue={systemInfoData?.monitorType}
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <InputBox
                            type="text"
                            name="displaySize"
                            label="Display Size"
                            defaultValue={systemInfoData?.displaySize}
                            disabled={!editEnabled}
                            onChange={handleChange}
                        />
                    </Col>
                </Row>
                <div className="d-flex justify-content-start align-items-center flex-wrap gap-4 p-4">
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="isOSGenuine"
                            label="Is OS Genuine"
                            checked={systemInfoData?.isOSGenuine}
                            name="isOSGenuine"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="isAntivirusInstalledOnAllSystem"
                            label="Is Antivirus Installed On All System"
                            checked={systemInfoData?.isAntivirusInstalledOnAllSystem}
                            name="isAntivirusInstalledOnAllSystem"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="isAntivirusLicensedVersion"
                            label="Is Antivirus is Licensed Version"
                            checked={systemInfoData?.isAntivirusLicensedVersion}
                            name="isAntivirusLicensedVersion"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="keyboardAvailable"
                            label="Keyboard Available"
                            checked={systemInfoData?.keyboardAvailable}
                            name="keyboardAvailable"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="mouseAvailable"
                            label="Mouse Available"
                            checked={systemInfoData?.mouseAvailable}
                            name="mouseAvailable"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                </div>
            </Card>
        </Container>
    )
}