import { faCheckCircle, faDownload, faEdit, faExclamation, faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Alert, Badge, Button, Card, Col, Container, Image, Row, Spinner } from 'react-bootstrap'
import ExtensionHelper from '../../../helpers/ExtensionHelper'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { UploadService } from '../../../services/upload.service'
import { CorpsService } from '../../../services/admin.service'
import PreviewModal from '../../../component/Modals/Preview.modal'
import MyUploader from '../../../component/Dropzone/dropzone'
import XcnButton from '../../../component/XcnButton/XcnButton'

const CenterDocument = () => {
    const params: any = useParams();

    const [loading, setLoading] = useState<boolean>(false)
    const [showPreviewModal, setPreviewModal] = useState<any>(undefined);
    const [editEnabled, setEditEnabled] = useState<boolean>(false);
    const [centerDocumentInfo, setCenterDocumentInfo] = useState({
        gstCerifiacte: "",
        organizationRegistrationCertificate: "",
        aadharFront: "",
        aadharBack: "",
        pan: "",
        cancelChecque: "",
        centerAgreement: "",
    })

    const s3Upload = async (fd: any, name: any) => {
        setLoading(true)
        await UploadService.uploadFileToS3(fd).then((res: any) => {
            if (res.status === 200) {
                let centerData = res.data && res.data[0];
                setCenterDocumentInfo({ ...centerDocumentInfo, [name]: centerData })
                toast.success(`File(s) uploaded`);
            } else {
                toast.error("Something went wrong");
            }
        }).catch(e => {
            toast.error(e.response.data);
        })
        setLoading(false)
    }


    const fileSelectedHandler = async (e: any, name: string) => {
        const fd = new FormData();
        // let arr=[];
        for (let i of e) {
            fd.append("upload", i);
        }
        toast.promise(async () => await s3Upload(fd, name), {
            pending: "Uploading...",
            success: "File(s) uploaded",
            error: "Something went wrong",
        })
    };

    const getCenterDetails = async () => {
        await CorpsService.getCenterById(params.id)
            .then((res) => {
                setCenterDocumentInfo(res.data.center.centerDocument);
            })
    };

    const updateCenterData = async () => {
        await CorpsService.updateCenterData(params.id, {
            centerDocument: centerDocumentInfo,
        })
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Data updated successfully.");
                    setEditEnabled(false);
                    getCenterDetails();
                }
            })
            .catch((error) => {
                toast.error(
                    `${error.response.data.message}`
                );
            });
    };

    function openPDF(pdf: any) {
        window.open(pdf, "_blank");
    }


    useEffect(() => {
        getCenterDetails();
    }, [])




    const renderCertificate = (certificateName: any, filePath: any, uploadLabelName: string, isMandatory: boolean, col?: number) => {
        return (
            <Row>
                <Col md={col || 12}>
                    <Card className="xcn-card mt-3">
                        <Card.Body>
                            <Row>
                                <Col md={3}>
                                    <div className="fw-bold h6 text-secondary d-flex justify-content-start align-items-center">
                                        <div>
                                            {certificateName}
                                        </div>
                                        <div>
                                            {isMandatory && <Badge bg="danger" className="ms-1 xcn-text-8">required</Badge>}
                                        </div>
                                    </div>


                                </Col>
                                <Col md={5}>
                                    <h3 className="text-primary text-decoration-none xcn-text-12">
                                        {
                                            filePath && <div className="d-flex justify-content-start align-items-center">
                                                <FontAwesomeIcon icon={faCheckCircle} className="me-1 text-success" /> File Already Uploaded click here to
                                                {
                                                    ExtensionHelper.isPdf(filePath) ?
                                                        <div className="ms-1">
                                                            <span className="text-success text-decoration-none xcn-link-pointer" onClick={() => openPDF(filePath)}>View/Download <FontAwesomeIcon icon={faDownload} /></span>
                                                        </div> :
                                                        <div className="ms-1">
                                                            <a className=" text-success text-decoration-none xcn-link-pointer" href={filePath} download>View/Download <FontAwesomeIcon icon={faDownload} /></a>
                                                        </div>
                                                }
                                            </div>
                                        }
                                    </h3>
                                    <MyUploader
                                        fileSelectedHandler={(e: any) =>
                                            fileSelectedHandler(e, uploadLabelName)
                                        }
                                    />
                                </Col>
                                <Col md={4}>
                                    <Alert variant="warning" className="mt-2">
                                        <div className="xcn-text-10 fw-bold">
                                            Upload Instructions:
                                        </div>
                                        <div className="xcn-text-10">
                                            1. Max allowed size is 5MB - PDF/IMAGE only
                                        </div>
                                        <div className="xcn-text-10">
                                            2. Background of the Image should be white
                                        </div>
                                    </Alert>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        )
    }

    return (
        <div>
            <PreviewModal
                show={showPreviewModal}
                setShow={setPreviewModal}
            />

            <Container className="" fluid>
                <>
                    <div className="d-flex justify-content-end">
                        {editEnabled ? (
                            <XcnButton
                                text={
                                    <>
                                        <FontAwesomeIcon icon={faSave} className="me-2" />
                                        Save
                                    </>
                                }
                                variant="success"
                                onClick={updateCenterData}
                            />
                        ) : (
                            <XcnButton
                                text={
                                    <>
                                        <FontAwesomeIcon icon={faEdit} className="me-2" />
                                        Edit
                                    </>
                                }
                                variant="warning"
                                onClick={() => setEditEnabled(true)}
                            />
                        )}
                        <div className="ms-2">
                            {loading === true && <Spinner animation='border' />}
                        </div>
                    </div>
                    {renderCertificate("GST Certificate", centerDocumentInfo?.gstCerifiacte, "gstCerifiacte", false)}
                    {renderCertificate("Organization Registration Certificate", centerDocumentInfo?.organizationRegistrationCertificate, "organizationRegistrationCertificate", false)}
                    {renderCertificate("Aadhar Copy Upload - Front", centerDocumentInfo?.aadharFront, "aadharFront", false)}
                    {renderCertificate("Aadhar Copy Upload - Back", centerDocumentInfo?.aadharBack, "aadharBack", true)}
                    {renderCertificate("Valid Pan Card Upload", centerDocumentInfo?.pan, "pan", true)}
                    {renderCertificate("Cancelled Cheque", centerDocumentInfo?.cancelChecque, "cancelChecque", true)}
                    {renderCertificate("Center Agreement", centerDocumentInfo?.centerAgreement, "centerAgreement", true)}
                </>
            </Container >
        </div >
    )
}

export default CenterDocument