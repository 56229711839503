import { METHODS } from "http";
import makeRequest, { makeParams } from "./makeRequest";
import urls from "./urls";

export class CorpsService {

    static async getCorpDetails() {
        return await makeRequest(urls.private.getCorpUser, "GET")
    }

    static async searchCorps(search: string, pageSize: number, pageNumber: number) {
        let params = makeParams([
            {
                index: "search",
                value: search
            },
            {
                index: "count",
                value: pageSize
            }, {
                index: "page",
                value: pageNumber
            }
        ])
        return await makeRequest(urls.private.searchCenter + params, "GET")
    }

    static async getAllCenters() {
        return await makeRequest(urls.private.getAllCenters, "GET")
    }

    static async generatePassword(id: any) {
        return await makeRequest(urls.private.generatePassword + "/" + id, "POST")
    }

    static async toggleStatus(id: any, payload: any) {
        return await makeRequest(urls.private.toggleStatusOfCenter + "/" + id, "PUT", payload)
    }

    static async deleteCenters(id: any) {
        return await makeRequest(urls.private.deleteCenters + "/" + id, "DELETE")
    }

    static async getCenterById(centerId: any) {
        return await makeRequest(urls.private.getCenterDetailsById + "/" + centerId, "GET")
    }

    static async getCenterLocationById(centerId: any) {
        return await makeRequest(urls.private.getCenterLocationById + "/" + centerId, "GET")
    }

    static async getBuildingsByCenterId(centerId: any) {
        return await makeRequest(urls.private.getBuildingsByCenterId + "/" + centerId, "GET")
    }

    static async getCenterEmployees(centerId: any) {
        return await makeRequest(urls.private.getCenterEmployees + "/" + centerId, "GET")
    }
    static async downloadCSV() {
        return await makeRequest(urls.private.downloadCSV, "GET")
    }

    static async getApiKeysForCenter(centerId: any) {
        return await makeRequest(urls.private.getApiKeysForCenter + "/" + centerId, "GET")
    }

    static async generateApiKey(centerId: any) {
        return await makeRequest(urls.private.createApiKey + "/" + centerId, "POST")
    }

    static async addCenter(centerDetails: any) {
        return await makeRequest(urls.private.addCenter, "POST", centerDetails)
    }

    static async getPincodeInfo(pincode: string) {
        return await makeRequest(urls.private.getPincodeInfo + "/" + pincode, "GET");
    }

    static async getCenterlabs(centerId: string) {
        return await makeRequest(urls.private.getCenterlabs + "/" + centerId, "GET");
    }

    static async approveAudit(centerId: string, approvalId: any, status: string) {
        return await makeRequest(urls.private.approveAudit + "/" + centerId + "/" + approvalId + "?status=" +status, "PUT");
    }

    static async updateCenterData(centerId: any, payload: any) {
        return await makeRequest(
            urls.private.updateCenterData + "/" + centerId,
            "PUT",
            payload
        );
    }

    static async updatedCenterBasicDetails(
        centerId: string,
        center: any,
        pincode: any) {
        return await makeRequest(urls.private.updateCenterBasicDetails + "/" + centerId, "PUT", { center, pincode })
    }

    static async updatedCenterFacilities(centerId: any, payload: any) {
        return await makeRequest(urls.private.updateCenterFacilities + "/" + centerId, "PUT", payload)
    }

    static async addLocation(centerId: any, payload: any) {
        return await makeRequest(urls.private.addLocation + "/" + centerId, "POST", payload)
    }


    static async exportCenterCSV(centerId?: any) {
        return await makeRequest(urls.private.exportCSV, "GET")
    }

    static async downloadCenterDetails(centerId: any) {
        return await makeRequest(urls.private.downloadCenterDetails + "/" + centerId, "GET")
    }

    static async saveBuildingImage(buildingId: any, payload: any) {
        return await makeRequest(urls.private.saveBuildingImage + "/" + buildingId, "POST", payload)
    }

    static async deleteBuildingImages(buildingId: any, payload: any) {
        return await makeRequest(urls.private.deleteBuildingImages + "/" + buildingId, "DELETE", payload)
    }

    static async getBuildingImages(buildingId: any) {
        return await makeRequest(urls.private.getBuildingImages + "/" + buildingId, "GET")
    }

    static async deleteLab(labId: any) {
        return await makeRequest(urls.private.deleteLb + "/" + labId, "DELETE")
    }
}