import React, { useState } from 'react';

import { Card, Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSave } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import XcnButton from '../../../component/XcnButton/XcnButton';
import { CorpsService } from '../../../services/admin.service';


interface ICenterContact {
  updateCenterFacilities: any,
  onChange: any
}
export default function CenterFacilities(props: ICenterContact) {
  const params: any = useParams();
  const [editCenterFacilities, setEditCenterFacilities] = useState<boolean>(true);

  const editButtonKey = () => {
    setEditCenterFacilities(!editCenterFacilities);
  }

  const updateCenterFacilities = async () => {
    await CorpsService.updatedCenterFacilities(params.id, props.updateCenterFacilities).then((res) => {
      if (res.status === 200) {
        toast.success("Data updated successfully.");
        setEditCenterFacilities(true);
      }
    })
      .catch((error) => {
        toast.error(error.response.status + " Something went wrong " + `${error.response.data.message}`);
      })
  }

  return (
    <Card className="xcn-card mt-3">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5 className="text-secondary fw-bold">Center Facilities</h5>
        <div>
          {editCenterFacilities ? (
            <XcnButton
              text={
                <>
                  <FontAwesomeIcon icon={faEdit} className="me-2" />
                  Edit
                </>
              }
              variant="warning"
              onClick={editButtonKey}
            />
          ) : (
            <XcnButton
              text={
                <>
                  <FontAwesomeIcon icon={faSave} className="me-2" />
                  Save
                </>
              }
              variant="success"
              onClick={updateCenterFacilities}
            />
          )}
        </div>
      </div>

      <div className="d-flex justify-content-start align-items-center">
        <span>
          <Form.Check
            inline
            type="switch"
            id="drinking-water"
            label="Drinking Water"
            checked={props.updateCenterFacilities?.drinkingWater}
            name="drinkingWater"
            disabled={editCenterFacilities}
            onChange={props.onChange}
          />
        </span>
        <span>
          <Form.Check
            inline
            type="switch"
            id="facilitiesForPhysicallyHandicapped"
            label="Facilities for Physically Handicapped"
            checked={
              props.updateCenterFacilities?.facilitiesForPhysicallyHandicapped
            }
            name="facilitiesForPhysicallyHandicapped"
            disabled={editCenterFacilities}
            onChange={props.onChange}
          />
        </span>
        <span>
          <Form.Check
            inline
            type="switch"
            id="luggageArea"
            label="Luggage Area"
            checked={props.updateCenterFacilities?.luggageArea}
            name="luggageArea"
            disabled={editCenterFacilities}
            onChange={props.onChange}
          />
        </span>
      </div>
      <div className="d-flex justify-content-start align-items-center mt-4">
        <span>
          <Form.Check
            inline
            type="switch"
            id="waitingArea"
            label="Waiting Area"
            checked={props.updateCenterFacilities?.waitingArea}
            name="waitingArea"
            disabled={editCenterFacilities}
            onChange={props.onChange}
          />
        </span>
        <span>
          <Form.Check
            inline
            type="switch"
            id="receptionArea"
            label="Reception Area"
            checked={props.updateCenterFacilities?.receptionArea}
            name="receptionArea"
            disabled={editCenterFacilities}
            onChange={props.onChange}
          />
        </span>
        <span>
          <Form.Check
            inline
            type="switch"
            id="washrooms"
            label="Washrooms"
            checked={props.updateCenterFacilities?.washrooms}
            name="washrooms"
            disabled={editCenterFacilities}
            onChange={props.onChange}
          />
        </span>
      </div>

      <div className="d-flex justify-content-start align-items-center mt-4">
        <span>
          <Form.Check
            inline
            type="switch"
            id="pwdVenue"
            label="PWD Venue"
            checked={props.updateCenterFacilities?.pwdVenue}
            name="pwdVenue"
            disabled={editCenterFacilities}
            onChange={props.onChange}
          />
        </span>{" "}
        <span>
          <Form.Check
            inline
            type="switch"
            id="labsOnGroundFloor"
            label="Labs on Ground Floor"
            checked={props.updateCenterFacilities.labsOnGroundFloor}
            name="labsOnGroundFloor"
            disabled={editCenterFacilities}
            onChange={props.onChange}
          />
        </span>{" "}
        <span>
          <Form.Check
            inline
            type="switch"
            id="lift"
            label="Lift"
            checked={props.updateCenterFacilities.lift}
            name="lift"
            disabled={editCenterFacilities}
            onChange={props.onChange}
          />
        </span>{" "}

      </div>
      <div className="d-flex justify-content-start align-items-center mt-4">
        <span>
          <Form.Check
            inline
            type="switch"
            id="wheelChair"
            label="Wheel Chair"
            checked={props.updateCenterFacilities.wheelChair}
            name="wheelChair"
            disabled={editCenterFacilities}
            onChange={props.onChange}
          />
        </span>{" "}
        <span>
          <Form.Check
            inline
            type="switch"
            id="cctv"
            label="CCTV"
            checked={props.updateCenterFacilities.cctv}
            name="cctv"
            disabled={editCenterFacilities}
            onChange={props.onChange}
          />
        </span>{" "}

        <span>
          <Form.Check
            inline
            type="switch"
            id="ramp"
            label="Ramp Facility"
            checked={props.updateCenterFacilities.ramp}
            name="ramp"
            disabled={editCenterFacilities}
            onChange={props.onChange}
          />
        </span>{" "}

      </div>
    </Card>
  );
}