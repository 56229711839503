const urls = {

  public: {
    login: "/public/corplogin"
  },
  private: {
    getCorpUser: "/corpuser/getcorpuserdetails",
    searchCenter: "/corpuser/centers/search",
    getAllCenters: "/corpuser/centers/getallcenters",
    generatePassword: "/corpuser/centers/resetCenterPassword",
    toggleStatusOfCenter: "/corpuser/centers/toggleStatus",
    deleteCenters: "/corpuser/centers",
    getCenterDetailsById: "/corpuser/centers/getCenterById",
    getCenterLocationById: "/corpuser/centers/getCenterLocation",
    getBuildingsByCenterId: "/corpuser/centers/getBuildingsByCenterId",
    getCenterEmployees: "/corpuser/centers/employees",
    downloadCSV: "/corpuser/centers/info",
    getApiKeysForCenter: "/corpuser/apikey",
    createApiKey: "/corpuser/apikey/create",
    addCenter: "/corpuser/addcenter",
    getPincodeInfo: "/corpuser/getPincodeInfo",
    getCenterlabs: "/corpuser/getCenterlabs",
    approveAudit: '/corpuser/centers/approval',
    updateCenterData: '/corpuser/centers/update',
    updateCenterBasicDetails: "/corpuser/centers/updateCenterBasicDetails",
    updateCenterFacilities: "/corpuser/centers/updateCenterFacilities",
    addLocation: "/corpuser/centers/addLocation",
    exportCSV: "/corpuser/centers/csv",
    downloadCenterDetails: "/corpuser/centers/details",
    getBuildingImages: "/corpuser/centers/getBuildingImages",
    saveBuildingImage: "/corpuser/centers/insertBuildingImages",
    deleteBuildingImages: "/corpuser/centers/deleteBuildingImages",
    deleteLb: "/corpuser/centers/removeLab"
  },
  common: {
    getPincodeInfo: "/corpuser/getPincodeInfo",
    getStates: "/protected/getStates",
    getCityByName: "/protected/getDistrictsByState",
    getAllCorps: "/protected/getAllCorpsId",
  },

};

export default urls