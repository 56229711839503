import React from 'react';
import { Badge } from 'react-bootstrap';

interface IBooleanStatusValue {
    true: string,
    false: string
}

interface IBooleanStatus {
    status: boolean
    reverse?: boolean
    value?: IBooleanStatusValue
}

export default function BooleanStatus(props: IBooleanStatus){
    let statusVal: boolean = props.status
    if(props.reverse) statusVal = !props.status;
    return(
            <>
                {statusVal ? 
                    <Badge bg="success"> {props.value && props.value.true ? props.value.true : "TRUE"}</Badge> : 
                    <Badge bg="danger"> {props.value && props.value.false ? props.value.false : "FALSE"}</Badge> 
                }
            </>
        
    )
}