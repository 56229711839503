import React, {useState} from 'react'
import {Container, Card} from 'react-bootstrap'
import XcnModal from './XcnModal'

interface IShowInfo {
  buildingInfo: any,
  setBuildingInfo: any,
  sendInfo: any
}

const InfoModal = ({buildingInfo, setBuildingInfo, sendInfo}: IShowInfo) => {
  return (
      <XcnModal
        show={buildingInfo}
        handleClose={() => setBuildingInfo(false)}    
        body = {
          <>
            <Container>
              <Card>{sendInfo}</Card>
            </Container>
          </>
        }     
        heading = ""
      />
  )
}

export default InfoModal