import React from 'react';

import { Routes, Outlet, Navigate, Route } from "react-router-dom"
import IRouter from '../interfaces/IRouter';
import AdminLogin from '../View/Login/AdminLogin';
import MainIndex from '../View/MainIndex';
import dashboardRoutes from './dashboard.routes';
import Auth from '../services/Auth';


function PrivateRouter({ children }: any) {
    const auth = Auth.checkAuth();//return true or false everytime
    return auth ? <MainIndex /> : <Navigate to="/login" />;// if user is already logged in then redirect to dashboard else redirect to login page
}





export default function MainRouter() {

return (
        <>
            <Routes>
                {/*path is dashboard the render privateRounter */}
                <Route path="/" element={<PrivateRouter />}>
                    {dashboardRoutes.map((data: IRouter) => {
                        // data will follow poperty of IRouter
                        return (
                            <Route
                                path={data.path + "/*"}
                                element={data.element}
                            />
                        )
                    })}
                </Route>
                {/* if path is login go for login*/}
                <Route path="/login" element={<AdminLogin />} />

                {/* if path is * then go for /login using navigate component */}
                <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
        </>
    )

}