import React, { useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { CorpsService } from '../../services/admin.service';


interface IEditModal {
    show: any;
    handleClose: any;
    reload: any
}


const CenterStatusModal = (props: IEditModal) => {
    const [isActive, setIsActive] = useState<boolean>(false);

    const handleStatusCheck = async (id: string, status: any) => {
        const payload = { status: status };
        setIsActive(true);
        await CorpsService.toggleStatus(id, payload)
            .then((res) => {
                if (res.status === 200) {
                    toast.success("updated successfully");
                    props.handleClose(false);
                    props.reload();
                }
            })
            .catch((err) => {
                toast.error("Unable to update");
                setIsActive(false);
            });
        setIsActive(false);
    };

    return (
        <Modal
            show={props.show}
            onHide={() => props.handleClose(false)}
            backdrop="static"
            keyboard={false}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>Edit Active/Inactive</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="d-flex align-items-center">
                    <Form.Label className="fw-bold me-2 mt-1">Status</Form.Label>
                    <Form.Check
                        type="switch"
                        checked={props.show && props.show.isActive}
                        onChange={(e: any) => {
                            handleStatusCheck(props.show && props.show._id, e.target.checked);
                        }}
                    />
                    {isActive === true && <Spinner className="d-flex align-items-center justify-content-center ms-2" animation="border" />}
                </Form.Group>
            </Modal.Body>
        </Modal>
    );
};

export default CenterStatusModal