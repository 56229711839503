import React from "react";
import { Card } from "react-bootstrap";

interface ICenterStaticInfo {
  centerDetails: any;
  centerPercentage: any;
}

export default function CenterStaticInfo(props: ICenterStaticInfo) {
  return (
    <>
      <Card className="xcn-card mt-3">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="text-secondary fw-bold">Center Information</h5>
        </div>

        <div className="d-flex flex-column justify-content-between align-items-left ">
          <div>
            <span className="text-muted  overflow-hidden text-truncate mt-2">
              Name:{" "}
              <span className="text-secondary xcn-text-12 fw-bold fw-bold">
                {" "}
                {props.centerDetails && props.centerDetails?.center?.name}{" "}
              </span>
            </span>
          </div>
          <div>
            <span className="text-muted">
              Center Id:{" "}
              <span className="text-info fw-bold">
                {" "}
                {props.centerDetails && props.centerDetails?.center?.centerId}{" "}
              </span>
            </span>
          </div>
          <div>
            <span className="text-muted">
              Corp Id:{" "}
              <span className="text-secondary xcn-text-12 fw-bold">
                {" "}
                {props.centerDetails && props.centerDetails?.center?.corpObjId}{" "}
              </span>
            </span>
          </div>
          {/* <div>
            <span className="text-muted">
              Completed:{" "}
              <span className="text-secondary xcn-text-12 fw-bold">
                {props?.centerPercentage?.completionPercent + " %"}
              </span>
            </span>
          </div> */}
        </div>
      </Card>
    </>
  );
}
