import React, { useEffect, useState } from 'react'
import { Card, Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom'
import { CorpsService } from '../../../services/admin.service'
import CenterBuildingsTable from './CenterBuildings.table';
import AddBuildingModal from '../../../component/Modals/AddBuilding.modal';
import XcnButton from '../../../component/XcnButton/XcnButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';



const Building = () => {

    const { id } = useParams();

    const [buildingData, setBuildingData] = useState<any>();
    const [showAddBuildingPlan, setShowAddBuildingPlan] =
        useState<boolean>(false);
    const [refreshBuildings, setReFreshBuildings] = useState<string>("")



    const getBuildingsByCenterId = async () => {
        await CorpsService.getBuildingsByCenterId(id).then((res) => {
            if (res.status === 200) {
                setBuildingData(res.data)
            }
        })
    }

    useEffect(() => {
        getBuildingsByCenterId();
    }, [])

    return (
        <>
            <Container fluid>
                <AddBuildingModal
                    showAddBuildingPlan={showAddBuildingPlan}
                    setShowAddBuildingPlan={setShowAddBuildingPlan}
                    reload={getBuildingsByCenterId}
                />
                <Card className="xcn-card mt-3">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h5 className="text-secondary fw-bold">Center Buildings</h5>
                        {/* <div>
                        <XcnButton
                            text={
                                <>
                                    Add New Building <FontAwesomeIcon icon={faPlusCircle} />
                                </>
                            }
                            onClick={() => setShowAddBuildingPlan(true)}
                            size="sm"
                        />
                    </div> */}
                    </div>
                    <div>
                        <CenterBuildingsTable
                            buildings={buildingData}
                        />
                    </div>
                </Card>
            </Container>
        </>
    )
}

export default Building