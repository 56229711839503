import React from 'react'
import { Image } from 'react-bootstrap'
import XcnModal from './XcnModal'

interface IPreviewModal {
    show: any,
    setShow: any
}

const PreviewModal = ({ show, setShow }: IPreviewModal) => {
    return (
        <>
            <XcnModal
                show={show ? true : false}
                handleClose={() => setShow(false)}
                body={
                    <>
                        <Image src={show} width="100%" />
                    </>
                }
                heading="Preview"
            />
        </>
    )
}

export default PreviewModal