import React from 'react'
import { Card, Container } from 'react-bootstrap'
import CenterApprovals from './CenterApprovals'

interface IApprovalInfo {
  data: any
  reload: any
}

const ApprovalInfo = ({ data, reload }: IApprovalInfo) => {
  return (
    <Container fluid>
      <Card className="xcn-card mt-3">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="text-secondary fw-bold">Audit Center Approvals</h5>
        </div>
        <CenterApprovals reload={reload} approvals={data?.approvalData} />
      </Card>
    </Container>
  )
}

export default ApprovalInfo