import { faCheckCircle, faEdit, faEllipsisVertical, faEye, faMessage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { Badge, Button, Dropdown, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import BooleanStatus from '../../../helpers/BooleanStatus';
import { CorpsService } from '../../../services/admin.service';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface ICenterApprovals {
  approvals: any[]
  reload: any
}

export default function CenterApprovals(props: ICenterApprovals) {

  const { id }: any = useParams();


  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {props}
    </Tooltip>
  );


  const approveAudit = async (approvalId: any, status: any) => {
    await CorpsService.approveAudit(id, approvalId, status).then(res => {
      if (res.status === 200) {
        toast.success("Audit Marked Successfully")
        props.reload()
      }
    }).catch(e => {
      console.log(e);
      toast.error("Unable to approve audit")
    })
  }


  return (
    <>
      <Table borderless striped>
        <thead>
          <tr>
            <th>Sr. No</th>
            <th>Approval Id</th>
            <th>Visited</th>
            <th>Total Nodes</th>
            <th>Approved/Rejected</th>
            <th>Approved Nodes</th>
            <th>Labs Approved</th>
            <th>Audit Reports</th>
            <th>Audit Remarks</th>
            <th>Approval/Status</th>
          </tr>
        </thead>
        <tbody>
          {
            props.approvals && props.approvals.length > 0 ?
              props.approvals.map((data: any, index: number) => {
                return (
                  <tr className="xcn-table-tr" key={data._id}>
                    <td>{index + 1}</td>
                    <td className="fw-bold xcn-text-12">
                      {data && data.approvalId}
                    </td>
                    <td>
                      <BooleanStatus
                        status={data && data.visited}
                        value={{
                          true: "Visited",
                          false: "Not Visited",
                        }}
                      />
                    </td>

                    <td className="font-monospace xcn-text-12">
                      {data && data.nodesAvailable}
                    </td>
                    <td className="fw-bold font-monospace xcn-text-12 text-secondary">
                      {moment(data && data.approvedOn).format(
                        "DD-MM-YYYY"
                      )}
                    </td>
                    <td>{data && data.nodesApproved}</td>
                    <td>{data && data.numberofLabsApproved}</td>
                    <td>
                      {data && data.auditReport ? (
                        <a
                          className="text-decoration-none"
                          href={data && data.auditReport}
                          download
                        >
                          Preview
                        </a>
                      ) : (
                        "--"
                      )}
                    </td>
                    <td>
                      {data && data.remarks ? (
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip(data && data.remarks)}
                        >
                          <span>
                            <FontAwesomeIcon
                              icon={faMessage}
                              className="text-primary"
                            />
                          </span>
                        </OverlayTrigger>
                      ) : (
                        "--"
                      )}
                    </td>
                    <td>
                      {(!data.status || (data.status != 'approved' && data.status != 'rejected')) ?
                        <> <Button
                          variant="warning"
                          size="sm"
                          onClick={() => approveAudit(data._id, "approved")}
                        >
                          Approve
                        </Button> <Button
                          variant="danger"
                          size="sm"
                          className='ms-2'
                          onClick={() => approveAudit(data._id, "rejected")}
                        >
                            Reject/Delete
                          </Button></> :
                        <>
                          <Badge>
                            {data.status ? data.status.toUpperCase() : "Pending"}
                          </Badge>
                        </>
                      }
                    </td>
                  </tr>
                );
              }) :
              <tr>
                <td colSpan={8} className="text-danger fw-bold">
                  No Approvals Registered
                </td>
              </tr>
          }

        </tbody>
      </Table>
    </>
  )
}