import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Form, Image, Row } from 'react-bootstrap'
import InputBox from '../../../component/Input/InputBox'
import { UploadService } from '../../../services/upload.service'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { CorpsService } from '../../../services/admin.service'
import { faSave, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import XcnButton from '../../../component/XcnButton/XcnButton'
import MyUploader from '../../../component/Dropzone/dropzone'
import Select from "react-select"


const Netwrok = () => {
    const params: any = useParams();

    const [editEnabled, setEditEnabled] = useState<boolean>(false);
    const [networkInfo, setNetworkInfo] = useState<any>({});


    const [selectedValue, setSelectedValue] = useState<any>();

    const handleSelectValue = (e: any, name: string) => {
        setNetworkInfo({ ...networkInfo, [name]: e.value })
    }


    const network_topology = [
        {
            label: 'Star',
            value: "Star"
        },
        {
            label: 'Bus',
            value: "Bus"
        },
        {
            label: 'Hybrid',
            value: "Hybrid"
        },
    ]

    const switches_types = [
        {
            label: 'MBPS',
            value: "MBPS"
        },
        {
            label: 'GBPS',
            value: "GBPS"
        },
    ]

    const network_types = [
        {
            label: 'LAN',
            value: "LAN"
        },
        {
            label: 'WAN',
            value: "WAN"
        },
    ]

    const switches_type = [
        {
            label: 'Manageable',
            value: "Manageable"
        },
        {
            label: 'Non-Manageable',
            value: "Non-Manageable"
        },
    ]

    const lan_connectivity = [
        {
            label: 'Cat 5E',
            value: "Cat 5E"
        },
        {
            label: 'CAT 6',
            value: "CAT 6"
        },
    ]

    const lan_types = [
        {
            label: "Single",
            value: "SIngle"
        }, {
            label: "Multiple",
            value: "Multiple"
        }
    ]

    const fileSelectedHandler = async (e: any, name: string) => {
        const fd = new FormData();
        // let arr=[];
        for (let i of e) {
            fd.append("upload", i);
        }
        await UploadService.uploadFileToS3(fd).then((res: any) => {
            if (res.status === 200) {
                setNetworkInfo({ ...networkInfo, [name]: res.data[0] });
                toast.success(`File(s) uploaded`);
            } else {
                toast.error("Something went wrong");
            }
        });
    };
    const handleChange = (e: any) => {
        let newData: any;
        if (e.target.type === "checkbox") {
            newData = { ...networkInfo, [e.target.name]: e.target.checked };
        } else {
            newData = { ...networkInfo, [e.target.name]: e.target.value };
        }
        setNetworkInfo(newData);
    };

    function removeEmpty(obj: any) {
        return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== ""));
    }

    const getCenterDetails = async () => {
        await CorpsService.getCenterById(params.id)
            .then((res) => {
                setNetworkInfo(res.data.center.networkDetails);
                setEditEnabled(false)
            })
            .catch((error) => {
                toast.error(
                    `${error.response.data.message}`
                );
            });
    };

    useEffect(() => {
        getCenterDetails();
    }, []);

    useEffect(() => {

    }, [networkInfo]);

    const updateCenterData = async () => {
        await CorpsService.updateCenterData(params.id, {
            networkDetails: removeEmpty(networkInfo),
        })
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Data updated successfully.");
                    setEditEnabled(false);
                    getCenterDetails();
                }
            })
            .catch((error) => {
                toast.error(
                    `${error.response.data.message}`
                );
            });
    };
    return (
        <>
            <Container fluid>
                <Card className="xcn-card mt-3">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h5 className="text-secondary fw-bold">
                            Network & IT Infrastructure
                        </h5>
                        <div>
                            {editEnabled ? (
                                <XcnButton
                                    text={
                                        <>
                                            <FontAwesomeIcon icon={faSave} className="me-2" />
                                            Save
                                        </>
                                    }
                                    variant="success"
                                    onClick={updateCenterData}
                                />
                            ) : (
                                <XcnButton
                                    text={
                                        <>
                                            <FontAwesomeIcon icon={faEdit} className="me-2" />
                                            Edit
                                        </>
                                    }
                                    variant="warning"
                                    onClick={() => setEditEnabled(true)}
                                />
                            )}
                        </div>
                    </div>
                    <Row>
                        <Col md={6}>
                            <InputBox
                                type="number"
                                name="totalLansAvailable"
                                label="Total LANs available and total used"
                                defaultValue={networkInfo?.totalLansAvailable}
                                disabled={!editEnabled}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col md={6}>
                            <InputBox
                                type="text"
                                name="ispName"
                                label="ISP name"
                                disabled={!editEnabled}
                                defaultValue={networkInfo?.ispName}
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md={6}>
                            <InputBox
                                type="number"
                                name="totalNodesAvailable"
                                label="Total nodes available and used in exams"
                                defaultValue={networkInfo?.totalNodesAvailable}
                                disabled={!editEnabled}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col md={6}>
                            <InputBox
                                type="number"
                                name="totalSwitchesAvailable"
                                label="Total number of switches available(Distribution & Access Layer Switches)"
                                defaultValue={networkInfo?.totalSwitchesAvailable}
                                disabled={!editEnabled}
                                onChange={handleChange}
                            />
                        </Col>

                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <InputBox
                                type="text"
                                name="internetDetails"
                                label="Internet Details (Speed & Provider details)"
                                defaultValue={networkInfo?.internetDetails}
                                disabled={!editEnabled}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col md={6}>
                            <InputBox
                                type="text"
                                name="networkSpeed"
                                label="Network Speed"
                                disabled={!editEnabled}
                                defaultValue={networkInfo?.networkSpeed}
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md={6}>
                            <InputBox
                                type="text"
                                name="coreSwitchSpeed"
                                label="Core switche speed"
                                defaultValue={networkInfo?.coreSwitchSpeed}
                                disabled={!editEnabled}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col md={6}>
                            <InputBox
                                type="text"
                                name="switchMakeModelNo"
                                label="Switch make model no."
                                disabled={!editEnabled}
                                defaultValue={networkInfo?.switchMakeModelNo}
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Switches Types (mbps/gbps)</Form.Label>
                                <Select
                                    options={switches_types}
                                    onChange={(e: any) => handleSelectValue(e, "switchType")}
                                    value={[{ label: networkInfo?.switchType, value: networkInfo?.switchType }]}
                                    isDisabled={!editEnabled}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Type of Network</Form.Label>
                                <Select
                                    options={network_types}
                                    onChange={(e: any) => handleSelectValue(e, "typeOfNetwork")}
                                    value={[{ label: networkInfo?.typeOfNetwork, value: networkInfo?.typeOfNetwork }]}
                                    isDisabled={!editEnabled}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Form.Group>
                                <Form.Label>Switch type Manageable/Non-Manageable</Form.Label>
                                <Select
                                    options={switches_type}
                                    onChange={(e: any) => handleSelectValue(e, "switchTypeManageableNonManagiable")}
                                    value={[{ label: networkInfo?.switchTypeManageableNonManagiable, value: networkInfo?.switchTypeManageableNonManagiable }]}
                                    isDisabled={!editEnabled}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Network Topology</Form.Label>
                                <Select
                                    options={network_topology}
                                    onChange={(e: any) => handleSelectValue(e, "networkTopology")}
                                    value={{ label: networkInfo?.networkTopology, value: networkInfo?.networkTopology }}
                                    isDisabled={!editEnabled}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>LAN Connectivity (CAT 5E/CAT 6) for Server</Form.Label>
                                <Select
                                    options={lan_connectivity}
                                    onChange={(e: any) => handleSelectValue(e, "LanConnectivity")}
                                    value={{ label: networkInfo?.LanConnectivity, value: networkInfo?.LanConnectivity }}
                                    isDisabled={!editEnabled}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Single or Multiple LAN/WAN</Form.Label>
                                <Select
                                    options={lan_types}
                                    onChange={(e: any) => handleSelectValue(e, "singleOrMultipleLanVlan")}
                                    value={{ label: networkInfo?.singleOrMultipleLanVlan, value: networkInfo?.singleOrMultipleLanVlan }}
                                    isDisabled={!editEnabled}
                                />
                            </Form.Group>
                        </Col>

                    </Row>

                    <div className="d-flex justify-content-start align-items-center flex-wrap gap-4 p-4">
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="networkKitsAvailable"
                                label="Network Kits Available"
                                // checked={props.updateCenterFacilities?.drinkingWater}
                                checked={networkInfo?.networkKitsAvailable}
                                name="networkKitsAvailable"
                                disabled={!editEnabled}
                                onChange={handleChange}
                            />
                        </span>
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="bufferNetworkSwitchAvailable"
                                label="Buffer Network Switch available"
                                checked={networkInfo?.bufferNetworkSwitchAvailable}
                                // checked={props.updateCenterFacilities?.drinkingWater}
                                name="bufferNetworkSwitchAvailable"
                                disabled={!editEnabled}
                                onChange={handleChange}
                            />
                        </span>
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="LanProperlyConnected"
                                label="LAN cabling properly connected"
                                checked={networkInfo?.LanProperlyConnected}
                                // checked={props.updateCenterFacilities?.drinkingWater}
                                name="LanProperlyConnected"
                                disabled={!editEnabled}
                                onChange={handleChange}
                            />
                        </span>
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="PatchCardCrimping"
                                label="Patch Card Crimping done properly"
                                checked={networkInfo?.PatchCardCrimping}
                                // checked={props.updateCenterFacilities?.drinkingWater}
                                name="PatchCardCrimping"
                                disabled={!editEnabled}
                                onChange={handleChange}
                            />
                        </span>
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="internetInAccessLayerSwitches"
                                label="Internet available in Distribution/Access Layer switches"
                                checked={networkInfo?.internetInAccessLayerSwitches}
                                // checked={props.updateCenterFacilities?.drinkingWater}
                                name="internetInAccessLayerSwitches"
                                disabled={!editEnabled}
                                onChange={handleChange}
                            />
                        </span>
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="extraLanUsedOutOfExamCenter"
                                label="Any extra LAN cables emerging out of the center from Lab"
                                checked={networkInfo?.extraLanUsedOutOfExamCenter}
                                // checked={props.updateCenterFacilities?.drinkingWater}
                                name="extraLanUsedOutOfExamCenter"
                                disabled={!editEnabled}
                                onChange={handleChange}
                            />
                        </span>
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="blankRJ45Connector"
                                label="Unused ports plugged with blank RJ45 connectors"
                                checked={networkInfo?.blankRJ45Connector}
                                // checked={props.updateCenterFacilities?.drinkingWater}
                                name="blankRJ45Connector"
                                disabled={!editEnabled}
                                onChange={handleChange}
                            />
                        </span>
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="separationOfExamLan"
                                label="Physical Separation of exam LAN from any other non-exam LAN"
                                checked={networkInfo?.separationOfExamLan}
                                // checked={props.updateCenterFacilities?.drinkingWater}
                                name="separationOfExamLan"
                                disabled={!editEnabled}
                                onChange={handleChange}
                            />
                        </span>
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="anyProxyOrFirewallAvailable"
                                label="Any proxy or firewall available"
                                checked={networkInfo?.anyProxyOrFirewallAvailable}
                                // checked={props.updateCenterFacilities?.drinkingWater}
                                name="anyProxyOrFirewallAvailable"
                                disabled={!editEnabled}
                                onChange={handleChange}
                            />
                        </span>
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="canDhcpServerBeSwitchedOffIfAvailable"
                                label="Can DHCP server be switched off (If Available)"
                                checked={networkInfo?.canDhcpServerBeSwitchedOffIfAvailable}
                                // checked={props.updateCenterFacilities?.drinkingWater}
                                name="canDhcpServerBeSwitchedOffIfAvailable"
                                disabled={!editEnabled}
                                onChange={handleChange}
                            />
                        </span>
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="lanCableAvailableForAllPCsThoughIoPort"
                                label="Lan cable available for all PC's though I/O port"
                                checked={networkInfo?.lanCableAvailableForAllPCsThoughIoPort}
                                // checked={props.updateCenterFacilities?.drinkingWater}
                                name="lanCableAvailableForAllPCsThoughIoPort"
                                disabled={!editEnabled}
                                onChange={handleChange}
                            />
                        </span>
                        <span>
                            <Form.Check
                                inline
                                type="switch"
                                id="canWirelessRouterBeSwitchedOffIfAvailable"
                                label="Can wireless router be switched off (If Available)"
                                checked={networkInfo?.canWirelessRouterBeSwitchedOffIfAvailable}
                                // checked={props.updateCenterFacilities?.drinkingWater}
                                name="canWirelessRouterBeSwitchedOffIfAvailable"
                                disabled={!editEnabled}
                                onChange={handleChange}
                            />
                        </span>
                    </div>

                    <Row>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="mt-4 text-secondary fw-bold w-100">
                                <h6>
                                    Network Architecture Diagram
                                </h6>
                                {editEnabled === false && networkInfo?.lanDiagram ? <>
                                    <Image src={networkInfo?.lanDiagram} width="100%" />
                                </> :

                                    <Row>
                                        <Col md={6}>
                                            <Image src={networkInfo?.lanDiagram} width="100%" />
                                        </Col>
                                        <Col md={6}>
                                            <MyUploader
                                                fileSelectedHandler={(e: any) =>
                                                    fileSelectedHandler(e, "lanDiagram")
                                                }
                                            />
                                        </Col>
                                    </Row>
                                }
                            </div>
                        </div>
                    </Row>
                </Card>
            </Container>
        </>
    )
}

export default Netwrok