import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, FloatingLabel, Form, Row, Table } from 'react-bootstrap'
import InputBox from '../../../component/Input/InputBox'
import { CorpsService } from '../../../services/admin.service'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import CenterContact from './CenterContact'
import CenterStaticInfo from './CenterStaticInfo'
import CenterFacilities from './CenterFacilities '
import { toast } from 'react-toastify'



const CenterDetails = () => {

    const { id } = useParams();

    const [apiKeys, setApiKeys] = useState([]);

    const [apiActive, setApiActive] = useState<boolean>(false)

    const [centerDetails, setCenterDetails] = useState<any>({});
    const [updateCenterDetails, setUpdateCenterDetails] =
        useState<any>();
    const [updatePincode, setUpdatePincode] = useState<any>({
        state: "",
        district: "",
        pincode: "",
    });

    const [coordinates, setCoordinates] = useState<any>({})


    const [updateCenterFacilities, setUpdateCenterFacilities] =
        useState<any>({
            drinkingWater: false,
            facilitiesForPhysicallyHandicapped: false,
            luggageArea: false,
            waitingArea: false,
            receptionArea: false,
            washrooms: false,
            googleCoordinate: "",
            venueSchedulingCount: "",
            venueSchedulingCountWithSD: "",
            cctv: true,
            pwdVenue: true,
            labsOnGroundFloor: false,
            lift: false,
            wheelChair: false,
            venuePersonCapacity: "",
            ramp: false,
            googleMapLink: "",
        });
        

    const getCenterDetails = async () => {
        await CorpsService.getCenterById(id).then(
            (res) => {
                const obj = res.data;
                setCenterDetails(obj);
                setUpdateCenterDetails({
                    centerContact: obj.center.centerContact,
                    centerEmail: obj.center.centerEmail,
                    address: obj.center.address,
                    name: obj.center.name,
                    gstNumber: obj.center.gstNumber,
                    spocName: obj.center.spocName,
                    taluka: obj.center.taluka,
                    nameOfDivisionHq: obj.center.nameOfDivisionHq,
                    venueItPersonName: obj.center.venueItPersonName,
                    venueITPersonContactNo: obj.center.venueITPersonContactNo,
                    venueItEmailId: obj.center.venueItEmailId,
                });
                setUpdatePincode({
                    state: obj.pincode.state,
                    district: obj.pincode.district,
                    pincode: obj.pincode.pincode,
                });
                setUpdateCenterFacilities({
                    drinkingWater: obj.center.drinkingWater,
                    facilitiesForPhysicallyHandicapped:
                        obj.center.facilitiesForPhysicallyHandicapped,
                    luggageArea: obj.center.luggageArea,
                    waitingArea: obj.center.waitingArea,
                    receptionArea: obj.center.receptionArea,
                    washrooms: obj.center.washrooms,
                    googleCoordinate: obj.center.googleCoordinate,
                    venueSchedulingCount: obj.center.venueSchedulingCount,
                    venueSchedulingCountWithSD: obj.center.venueSchedulingCountWithSD,
                    cctv: obj.center.cctv,
                    pwdVenue: obj.center.pwdVenue,
                    labsOnGroundFloor: obj.center.labsOnGroundFloor,
                    lift: obj.center.lift,
                    wheelChair: obj.center.wheelChair,
                    venuePersonCapacity: obj.center.venuePersonCapacity,
                    ramp: obj.center.ramp,
                    googleMapLink: obj.center.googleMapLink,
                });
            }
        );
    };

    const handlePincodeDetails = (e: any) => {
        setUpdatePincode({ ...updatePincode, [e.target.name]: e.target.value });
    };

    const handleCenterFacilities = (e: any) => {
        const value =
            e.target.type === "checkbox" ? e.target.checked : e.target.value;

        const centerFacilitiesData: any = {
            ...updateCenterFacilities,
            [e.target.name]: value,
        };
        setUpdateCenterFacilities(centerFacilitiesData);
    };

    const handleCenterContactDetailsChange = (e: any) => {
        const centerDetailData: any = {
            ...updateCenterDetails,
            [e.target.name]: e.target.value,
        };
        setUpdateCenterDetails(centerDetailData);
    };

    const generateApiKey = async () => {
        setApiActive(true);
        await CorpsService.generateApiKey(id).then(res => {
            if (res.status === 200) {
                getApiKeys();
            }
        }).catch(err => {
            console.log(err);
            toast.error(err.response.data.message)
            setApiActive(false)
        })
        setApiActive(false)
    }

    const getApiKeys = async () => {
        await CorpsService.getApiKeysForCenter(id).then(res => {
            if (res.status === 200) {
                setApiKeys(res.data.keys)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        getApiKeys();
        getCenterDetails();
    }, [])


    return (
        <>
            <Container fluid>
                <Row>
                    <Col md={7}>
                        <CenterContact
                            onChange={handleCenterContactDetailsChange}
                            updateCenterDetails={updateCenterDetails}
                            updateCenterFacilities={updateCenterFacilities}
                            onUpdatingFacilities={handleCenterFacilities}
                            handlePincodeDetails={handlePincodeDetails}
                            pincode={updatePincode}
                            reload={getCenterDetails}
                            coordinatesData={""}
                        />
                    </Col>

                    <Col md={5}>
                        <Row>
                            <Col>
                                <CenterStaticInfo
                                    centerDetails={centerDetails}
                                    centerPercentage={""}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <CenterFacilities
                                    onChange={handleCenterFacilities}
                                    updateCenterFacilities={updateCenterFacilities}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card className="xcn-card mt-3">
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <h5 className="text-secondary fw-bold">API Keys</h5>
                                        <Button size="sm" onClick={generateApiKey} disabled={apiActive}>
                                            Generate Key
                                        </Button>
                                    </div>
                                    <div className="d-flex flex-column justify-content-between align-items-left ">
                                        {apiKeys && apiKeys.length > 0 ?
                                            <Table striped responsive hover>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            S.No.
                                                        </th>
                                                        <th>
                                                            Valid Upto
                                                        </th>
                                                        <th>
                                                            Key Value
                                                        </th>
                                                        <th>
                                                            Active
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {apiKeys.map((data: any, index: number) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    {index + 1}
                                                                </td>
                                                                <td>
                                                                    {moment(data.validUpto).format("DD-MM-YY HH:mm")}
                                                                </td>
                                                                <td className='font-monospace' style={{ fontSize: 12 }}>
                                                                    {data.apiKey}
                                                                </td>
                                                                <td>
                                                                    {data.isActive && data.blocked === false ?
                                                                        <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
                                                                        : <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table> : <div>
                                                No API Keys Available
                                            </div>}
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Container>
        </>
    )
}

export default CenterDetails