export default class Auth {
    static checkAuth () {
        //if auth-token present then return true else return false
        return sessionStorage.getItem('authToken')?true:false
    }
    static clearAuthToken() {
        sessionStorage.clear();
        // ('authToken');
        // sessionStorage.removeItem('role');
        return true;
    }
}