import React from 'react'
import { Card, Container } from 'react-bootstrap'
import CenterEmployees from './CenterEmployees'

interface IEmployees {
  employeeDetails: any
}

const Employees = ({ employeeDetails }: IEmployees) => {
  return (
    <Container fluid>
      <Card className="xcn-card mt-3">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="text-secondary fw-bold">Center Employees</h5>
        </div>
        <CenterEmployees employees={employeeDetails} />
      </Card>
    </Container>
  )
}

export default Employees