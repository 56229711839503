import React, { useEffect, useState } from "react";
import { Button, Card, FloatingLabel, Form, Modal, Table } from "react-bootstrap";
import { ExportToCsv } from "export-to-csv-fix-source-map";
import { faDownload, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

interface IViewLabComputers {
    show: boolean;
    handleClose: any;
    data: any;
    selectedLabId: string,
}


export default function ViewLabComputers(props: IViewLabComputers) {

    let csvOptions: any = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: true,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(csvOptions);


    const downloadData = () => {
        csvExporter.generateCsv(props.data.computerIds.map((data: any) => {
            return {
                ...data,
                screen_resolution: JSON.stringify(data.screen_resolution),
                open_ports: JSON.stringify(data.open_ports)
            }
        }))
    }

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Computer Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Button size="sm" variant="outline-primary" onClick={downloadData}>Download Lab Data <FontAwesomeIcon icon={faDownload} /></Button>
                    <Card className="xcn-card mt-4">
                        <Table borderless striped>
                            <thead>
                                <tr>
                                    <th>cid</th>
                                    <th>mac</th>
                                    <th>open ports</th>
                                    <th>screen resolution</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.data.computerIds?.map((dets: any) =>
                                    <tr className="xcn-table-tr">
                                        <td className="xcn-text-10 font-monospace">{dets.cid}</td>
                                        <td className="xcn-text-10 font-monospace">{dets.mac}</td>
                                        <td className="xcn-text-12 font-monospace">{dets.open_ports.length}</td>
                                        <td className="xcn-text-12 font-monospace">{JSON.stringify(dets.screen_resolution)}</td>
                                    </tr>
                                )
                                }

                            </tbody>
                        </Table>
                    </Card>
                </Modal.Body>
            </Modal>
        </>
    );
}
